<template>
  <div class="new-order-page">
    <h1>{{ $t("newOrderTitle") }}</h1>

    <div class="prog-bar">
      <ul class="step-pog-bar">
        <li v-for="step in 5" :key="step" :class="orderStep >= step && 'active'">
          {{ $t("orderStep_" + step) }}
        </li>
      </ul>
    </div>
    <br />
    <h1 class="text-center">
      <span v-if="this.orderStep === 2 && this.orderType === 'DUPLICATE'">
        {{ $t("orderStep_2_title_duplicate") }}
      </span>
      <span v-else>{{ $t("orderStep_" + this.orderStep + "_title") }}</span>
    </h1>

    <h6 v-if="this.userRole === 'FAC' && this.orderStep > 1" align="center">
      <span v-if="this.orderType === 'PAPER'">{{ $t("orderTypePaper") }}</span>
      <span v-else-if="this.orderType === 'NEWCR'">{{
        $t("orderTypeTopup")
      }}</span>
      <span v-else-if="this.orderType === 'NEW'">{{
        $t("orderTypeCard")
      }}</span>
      <span v-else-if="this.orderType === 'DUPLICATE'">{{
        $t("orderTypeDuplicate")
      }}</span>
      <span v-else>{{ $t("orderTypeRenewalCard") }}</span>

      <span v-if="this.orderStep > 2">&nbsp; > &nbsp;{{ $t("commercialPerson") + " : " + this.capitalizeName }}&nbsp;
      </span>
    </h6>

    <h6 v-if="this.orderStep > 2 && this.orderType !== 'PAPER'" align="center">
      <span>{{
        getProductNameMethod(
          this.productCustomer.card === 0
            ? this.productCustomer.paper
            : this.productCustomer.card_profile
              ? parseInt(this.productCustomer.card + "" + this.productCustomer.card_profile)
              : this.productCustomer.card
        )
      }}</span>
    </h6>

    <h6 v-if="this.orderStep === 4" align="center">
      <span class="warning-text">{{ $t("orderMessageForSuccess") }}</span>
    </h6>

    <div v-if="this.orderStep > 1 && this.orderStep < 5">
      <h3 class="return">
        <a href="#" @click="backOneStep()">
          <font-awesome-icon icon="chevron-left" />
          <span>{{ $t("backButtonLabel") }}</span>
        </a>
      </h3>
    </div>

    <!-- order step 1, type of order -->
    <table v-if="this.orderStep === 1" cellpadding="0" cellspacing="10" width="90%" align="center"
      class="choose mobile-table">
      <tr>
        <td width="50%">
          <a href="#" class="plastic-cards step1" @click="selectOrderType('NEW')">{{ $t("orderTypeCard") }}</a>
        </td>
        <td width="50%">
          <a href="#" class="top-up-cards step1" @click="selectOrderType('NEWCR')">{{ $t("orderTypeTopup") }}</a>
        </td>
      </tr>
      <tr>
        <td width="50%">
          <a href="#" class="duplicate-order step1" @click="selectOrderType('DUPLICATE')">{{ $t("orderTypeDuplicate")
          }}</a>
        </td>
        <td width="50%">
          <a href="#" class="renewal-card step1" @click="selectOrderType('REN')">{{ $t("orderTypeRenewalCard") }}</a>
        </td>
      </tr>
      <tr>
        <td width="50%">
          <a href="#" class="p-voucher step1" @click="selectOrderType('PAPER')">{{ $t("orderTypePaper") }}</a>
        </td>
      </tr>
    </table>

    <!-- order step 2, product brand -->
    <div v-if="this.orderStep === 2">
      <div class="products" v-if="this.orderType !== 'DUPLICATE'">
        <b-container class="border-box" v-if="this.orderType === 'PAPER'">
          <b-row class="border-box" align-v="center">
            <b-col class="border-box margins" lg="3" md="3" sm="6" v-for="(entry, index) in this.orderProducts"
              :key="index" align-v="center">
              <a v-if="customerHasPaperProduct(entry) === true" class="cursor-pointer step2"
                @click="selectProduct(entry)">
                <img :src="
                  require('@/assets/images/choose-up-' + entry.name + '.png')
                " />
              </a>
              <a v-else class="cursor-pointer d-inline-block position-relative w-100"
                @click="showModalPopup('modal_popup_products', true)">
                <img :src="
                  require('@/assets/images/choose-up-' +
                    entry.name +
                    '-disabled.png')
                " />
                <span class="image-button">
                  {{ $t("orderProductDisabledButton") }}
                </span>
              </a>
            </b-col>
          </b-row>
        </b-container>
        <b-container class="border-box" v-else>
          <b-row class="border-box" align-v="center">
            <b-col class="border-box margins" lg="3" md="3" sm="6" v-for="(entry, index) in this.orderProducts"
              :key="index" align-v="center">
              <a v-if="customerHasCardProduct(entry) === true" class="cursor-pointer step2" @click="selectProduct(entry)">
                <img :src="
                  require('@/assets/images/choose-up-' + entry.name + '.png')
                " />
              </a>
              <a v-else class="cursor-pointer d-inline-block position-relative w-100"
                @click="showModalPopup('modal_popup_products', true)">
                <img :src="
                  require('@/assets/images/choose-up-' +
                    entry.name +
                    '-disabled.png')
                " />
                <span class="image-button">
                  {{ $t("orderProductDisabledButton") }}
                </span>
              </a>
            </b-col>

          </b-row>
        </b-container>
      </div>

      <div class="container-alb filter-mobile" v-if="this.orderType === 'DUPLICATE'">
        <div v-if="customer.products.length > 0">
          <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
            <tr>
              <td>
                <img src="@/assets/images/filter.svg" />
                <span class="mobile-show margin-left-5">
                  {{ $t("invoicesFilterTitle") }}
                </span>
              </td>
              <td>
                {{ $t("invoicesFilterProduct") }}
                <select class="filtrare" @change="selectProductDuplicate">
                  <option v-for="(entry, index) in this.customer.products" :key="index" :value="index" :selected="
                    entry.card === newOrder.product.card ||
                    entry.paper === newOrder.product.paper
                  ">
                    {{
                      getProductNameMethod(
                        entry.card === 0
                          ? entry.paper
                          : entry.card_profile
                            ? parseInt(entry.card + "" + entry.card_profile)
                            : entry.card
                      )
                    }}
                  </option>
                </select>
              </td>
              <td>
                {{ $t("invoicesFilterPeriod") }}
                <date-range class="invoices-filter" @close="periodFilterChanged" :time="this.filters.period"
                  :noRanges="false" :single="false" />
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="container-alb" v-if="this.orderType === 'DUPLICATE'">
        <div v-if="customer.products.length > 0">
          <orders-table-desktop @change="sortOrdersBy" @click="selectOrder" :companyOrders="previousOrders"
            :pending="false" :duplicate="true" :company="this.customer" />
        </div>
      </div>
    </div>

    <!-- order step 3, configure order -->
    <div v-if="this.orderStep === 3">
      <h3 v-if="this.todayOrder > 0" class="warning-text">
        {{
          $t("hasAnotherOrder", {
            count: todayOrder,
            orderType:
              newOrder.type === "PAPER"
                ? $t("orderTypePaper")
                : newOrder.type === "NEW"
                  ? $t("orderTypeCard")
                  : $t("orderTypeTopup"),
            product: newOrder.product.name
          })
        }}
      </h3>

      <div v-if="
        this.newOrder.type === 'PAPER' &&
        (this.newOrder.product.paper === 4 ||
          this.newOrder.product.order === 3)
      ">
        <p>
          <strong>{{ $t("orderChooseTicketType") }}</strong>
        </p>
        <div>
          <a class="personalised-vouchers" :class="newOrder.personalized && 'active'"
            @click="newOrder.personalized = true">{{ $t("orderPersonalizedLabel") }}</a>
          <a class="nonpersonalised-vouchers" :class="!newOrder.personalized && 'active'" @click="
            newOrder.personalized = false;
          order_type = true; ">{{ $t("orderNotPersonalizedLabel") }}</a>
        </div>
        <br clear="all" />
      </div>
      <div>
        <p>
          <strong>{{ $t("orderAddBeneficiariesTitle") }}</strong>
        </p>
        <div class="container-alb padding-top-0">
          <div class="navigationSlider order-type">
            <a v-if="
              (this.newOrder.type !== 'REN' ) || (this.newOrder.type === 'REN' && this.userRole === 'FAC')
            " @click="order_type = true" class="cardusersTab" :class="order_type && 'active'">
              <span v-if="this.userRole === 'FAC'">{{ $t("commentsOrderTabTitle") }}</span>
              <span v-else>{{ $t("manualOrderTabTitle") }} </span>
            </a>
            <a v-if="this.newOrder.personalized" @click="order_type = false" class="companiesTab" :class="
              !order_type && 'active'
            ">{{ $t("excelOrderTabTitle") }}</a>
          </div>


          <div v-if="
            order_type === false 
          ">
            <table v-if="this.newOrder.type === 'PAPER'" cellpadding="20" cellspacing="0" width="95%"
              class="hover-table mobile-table" align="center">
              <tr>
                <td colspan="3">
                  <p>
                    <strong>{{ $t("orderExcelTemplateTitle") }}</strong>
                  </p>
                </td>
              </tr>

              <tr v-for="rowIndex in 3" :key="rowIndex">
                <td align="center" v-for="colIndex in 3" :key="colIndex">
                  {{
                    $t("orderExcelColumnLabel", {
                      index: 3 * (rowIndex - 1) + colIndex
                    })
                  }}
                  <select class="filtrare select-column" :class="
                    newOrder.excelColumns[3 * (rowIndex - 1) + colIndex - 1]
                      .code > 0 && 'warning-max-value'
                  " @change="
  selectExcelColumn(
    3 * (rowIndex - 1) + colIndex - 1,
    $event.target.value
  )
">
                    <option :selected="true" :value="
                      newOrder.excelColumns[3 * (rowIndex - 1) + colIndex - 1]
                        .code
                    " v-if="
  newOrder.excelColumns[3 * (rowIndex - 1) + colIndex - 1]
    .code > 0
">
                      {{
                        $t(
                          "orderExcelColumn_" +
                          newOrder.excelColumns[
                            3 * (rowIndex - 1) + colIndex - 1
                          ].code
                        )
                      }}
                    </option>

                    <option v-for="column in availableExcelColumns" :key="column" :value="column">
                      {{ $t("orderExcelColumn_" + column) }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <td colspan="12" class="text-right">
                  <a class="status-background background-will-expire" @click="resetTableExcel()">Reset</a>
                </td>
              </tr>
            </table>

            <table cellpadding="20" cellspacing="0" width="95%"
              class="hover-table mobile-table" align="center">
              <tr>
                <td valign="middle">
                  <strong>{{ $t("orderFileModelText") }}</strong>
                </td>
              </tr>
              <tr v-if="
                this.newOrder.type === 'NEW' &&
                this.newOrder.product.card !== 11 &&
                customerHasCardProductDejun()
              ">
                <td valign="middle">
                  <input type="checkbox" class="checkbox-custom" v-model="newOrder.fileBeneficiaries" />
                  <strong>{{ $t("orderFileBeneficiariesLabel") }}:</strong>
                </td>
              </tr>
              <tr v-if="this.newOrder.type === 'REN' ">
                <td valign="middle">
                  <input type="checkbox" class="checkbox-custom" v-model="newOrder.fileRenewAddress" />
                  <strong>{{ $t("orderFileBeneficiariesRenLabel") }}:</strong>
                </td>
              </tr>
              <tr>
                <td valign="middle">
                  <a class="status-background background-active" @click="downloadModel">{{ $t("downloadLinkText") }}</a>
                </td>
              </tr>
            </table>

            <table cellpadding="20" cellspacing="0" width="95%" class="hover-table mobile-table" align="center">
              <tr>
                <td colspan="3">
                  <strong>{{ $t("orderUploadFileTitle") }}</strong>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <strong>{{ $t("orderAddFileButtonLabel") }}</strong>
                </td>
                <td colspan="2">
                  {{ $t("orderFileUploadText") }}
                  <br />
                  <br />

                  <b-form-file v-model="fileReceived" ref="file" :accept="acceptedFileTypes"
                    :placeholder="[[$t('uploadFileLabel')]]" @input="uploadFile"></b-form-file>
                  <br />
                  <br />

                  <div class="w-100 text-right">
                    <a @click="fileReceived = null" class="status-background background-will-expire">Reset</a>
                  </div>
                </td>
              </tr>
              <tr v-if="
                this.newOrder.type === 'NEWCR' &&
                this.newOrder.product.card === 11
              ">
                <td valign="top">
                  <strong>{{ $t("orderMonthLabel") }}</strong>
                </td>
                <td colspan="2">
                  <select class="filtrare width-auto" v-model="newOrder.orderMonth">
                    <option v-for="monthIndex in this.noOfMonths + 1" :key="monthIndex" :value="monthIndex - 1">
                      {{ $t("orderMonth_" + (monthIndex - 1)) }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr v-if="
                this.newOrder.type === 'NEWCR' &&
                (this.userRole === 'FAC' || this.customer.future_loading)
              ">
                <td style="width: 130px" valign="middle">
                  <strong>{{ $t("orderValidFromLabel") }}:</strong>
                </td>
                <td>
                  <date-range ref="dateOrder" class="invoices-filter" :minDateOrder="minDateValidFrom" :noRanges="true"
                    :single="true" :time="this.period" @close="changeValidDate" />
                </td>
                <td>
                  <div class="width-60">
                    <i>{{ $t("orderValidFromText") }}</i>
                  </div>
                </td>
              </tr>
              <tr v-if="
              this.newOrder.type === 'NEWCR' && this.newOrder.product.card === 15 && this.userRole === 'FAC' 
            ">
              <td style="width: 130px" valign="middle">
                <strong>{{ $t("orderValidToLabel") }}:</strong>
              </td>
              <td valign="middle">
                <date-range ref="dateOrder" class="invoices-filter" :minDateOrder="minDateValidFrom" :noRanges="true"
                  :single="true" :time="this.periodTo" @close="changeValidToDate" />
              </td>
              <!-- <td>
                <div class="width-60">
                  <i>{{ $t("orderValidToText") }}</i>
                </div>
              </td> -->
            </tr>
              <tr v-if="this.newOrder.type === 'NEWCR' && this.userRole === 'FAC'">
                <td style="width: 130px" valign="middle">
                  <strong>{{ $t("orderIgnoreMaxTicketsLabel") }}:</strong>
                </td>
                <td valign="middle" colspan="2">
                  <input type="checkbox" class="checkbox-custom" v-model="newOrder.ignoreMaxTickets" />
                </td>
              </tr>
              <tr v-if="(this.newOrder.type === 'NEWCR' || this.newOrder.type === 'NEW') && this.userRole === 'FAC'">
                <td style="width: 110px" valign="middle">
                  <strong>{{ $t("orderIgnoreCnpValidLabel") }}:</strong>
                </td>
                <td valign="middle">
                  <input type="checkbox" class="checkbox-custom" v-model="newOrder.ignoreCnpValid" />
                </td>
              </tr>
              <tr v-if="(this.newOrder.type === 'REN') && this.userRole === 'FAC'">
                <td  valign="middle" colspan="3">
                  <strong>{{ $t("renewForCurrentLabel") }}</strong> &nbsp;&nbsp;&nbsp;
                  <input type="checkbox" class="checkbox-custom" v-model="newOrder.renewForCurrentMonth" />
                  
                </td>
              </tr>
            </table>
          </div>

          <!-- customer comments -->
          <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center" v-if="
            this.userRole == 'FAC' &&
            order_type === true 
          ">
            <tr>
              <td>
                <strong>{{ $t('invoiceDateHeader') }}</strong>
              </td>
              <td>
                <strong>{{ $t('invoiceAmountHeader') }}</strong>
              </td>
            </tr>
            <tr v-for="(entry, index) in this.customerComments" :key="index" class="trhover parent"
              :class="index % 2 === 0 && 'gri-cell'">
              <td valign="middle">
                {{ displayDate(entry.date) }}
              </td>
              <td>
                <p v-html="entry.value"></p>
              </td>
            </tr>
          </table>

          <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center" v-if="
            this.userRole != 'FAC' &&
            order_type === true &&
            this.newOrder.type !== 'REN' 
          ">
            <tr v-if="
              this.newOrder.product.card !== 11 &&
              this.newOrder.type === 'NEW' &&
              customerHasCardProductDejun()
            ">
              <td colspan="4" valign="middle">
                <a class="status-background background-active" @click="loadCustomerBeneficiaries">{{
                  $t("orderFileBeneficiariesButtonLabel") }}</a>
              </td>
            </tr>
            <tr v-if="this.newOrder.personalized">
              <td style="width: 110px" valign="middle">
                <strong>{{ $t("deliveryPointNoBeneficiaries") }}:</strong>
                &nbsp; &nbsp;
              </td>
              <td valign="middle">
                <img src="@/assets/images/user-gri.svg" width="20" />&nbsp;
                <strong v-if="this.customerBeneficiaries">
                  {{ this.customerBeneficiaries.length }}
                </strong>
                <strong v-else>0</strong>
              </td>

              <td align="right" colspan="4">
                <div style="position: relative; text-align: left">
                  <div style="
                                      display: block;
                                      position: relative;
                                      max-width: 300px;
                                      width: 100%;
                                    ">
                    <form @submit.prevent="
                      {
                        return false;
                      }
                    ">
                      <input type="text" v-bind:placeholder="$t('beneficiariesFilterKeyword')"
                        class="formedituser search-input" v-model="keyword"
                        v-on:keyup.enter="searchCustomerBeneficiaries()" />
                      <a class="search-btn cursor-pointer" @click="searchCustomerBeneficiaries()">
                      </a>
                    </form>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="
              this.newOrder.type === 'NEWCR' &&
              this.newOrder.product.card === 11
            ">
              <td style="width: 110px" valign="middle">
                <strong>{{ $t("orderMonthLabel") }}:</strong>
              </td>
              <td valign="middle">
                <select class="filtrare width-auto" v-model="newOrder.orderMonth">
                  <option v-for="monthIndex in this.noOfMonths + 1" :key="monthIndex" :value="monthIndex - 1">
                    {{ $t("orderMonth_" + (monthIndex - 1)) }}
                  </option>
                </select>
              </td>
            </tr>
            <tr v-if="
              this.newOrder.type === 'NEWCR' &&
              (this.userRole === 'FAC' || this.customer.future_loading)
            ">
              <td style="width: 130px" valign="middle">
                <strong>{{ $t("orderValidFromLabel") }}:</strong>
              </td>
              <td valign="middle">
                <date-range ref="dateOrder" class="invoices-filter" :minDateOrder="minDateValidFrom" :noRanges="true"
                  :single="true" :time="this.period" @close="changeValidDate" />
              </td>
              <td>
                <div class="width-60">
                  <i>{{ $t("orderValidFromText") }}</i>
                </div>
              </td>
            </tr>
            <tr v-if="
              this.newOrder.type === 'NEWCR' && this.newOrder.product.card === 15 && this.userRole === 'FAC' 
            ">
              <td style="width: 130px" valign="middle">
                <strong>{{ $t("orderValidToLabel") }}:</strong>
              </td>
              <td valign="middle">
                <date-range ref="dateOrder" class="invoices-filter" :minDateOrder="minDateValidFrom" :noRanges="true"
                  :single="true" :time="this.periodTo" @close="changeValidToDate" />
              </td>
              <!-- <td>
                <div class="width-60">
                  <i>{{ $t("orderValidToText") }}</i>
                </div>
              </td> -->
            </tr>
            <tr v-if="this.newOrder.type === 'NEWCR' && this.userRole === 'FAC'">
              <td style="width: 110px" valign="middle">
                <strong>{{ $t("orderIgnoreMaxTicketsLabel") }}:</strong>
              </td>
              <td valign="middle">
                <input type="checkbox" class="checkbox-custom" v-model="newOrder.ignoreMaxTickets" />
              </td>
            </tr>
            <tr
              v-if="(this.newOrder.type === 'NEWCR' || this.newOrder.type === 'NEW') && this.userRole === 'FAC' && this.customer.company_id === 88866">
              <td style="width: 110px" valign="middle">
                <strong>{{ $t("orderIgnoreCnpValidLabel") }}:</strong>
              </td>
              <td valign="middle">
                <input type="checkbox" class="checkbox-custom" v-model="newOrder.ignoreCnpValid" />
              </td>
            </tr>
            <tr v-if="
              this.newOrder.type === 'NEWCR' ||
              (this.orderType === 'DUPLICATE' &&
                this.newOrder.type === 'PAPER')
            ">
              <td style="width: 110px" valign="middle">
                <strong>{{ $t("beneficiariesTableNoOfTickets") }}:</strong>
              </td>
              <td valign="middle">
                <input type="number" class="input-add-beneficiary" style="width: 65px" v-model="defaultVouchers" />
              </td>
            </tr>
            <tr v-if="
              this.newOrder.type === 'NEWCR' ||
              (this.orderType === 'DUPLICATE' &&
                this.newOrder.type === 'PAPER')
            ">
              <td style="width: 110px" valign="middle">
                <strong>{{ $t("beneficiariesTableTicketValue") }}:</strong>
              </td>
              <td valign="middle">
                <span class="currencyinput" :class="
                  parseFloat(defaultTicketValue) ===
                  parseFloat(newOrder.product.maxTicketValue) &&
                  'warning-max-value'
                ">
                  <input v-if="newOrder.product.permittedValues === ''" type="text" :class="
                    parseFloat(defaultTicketValue) ===
                    parseFloat(newOrder.product.maxTicketValue) &&
                    'text-white'
                  " style="width: 40px" v-model="defaultTicketValue" v-on:blur="
  defaultTicketValue = formatTicketValue(defaultTicketValue)
" @keyup="
  defaultTicketValue = convertValueToDecimalVariable(
    defaultTicketValue
  )
" class="input-add-beneficiary amount" />
                  <select v-else v-model="defaultTicketValue" style="width: 45px" :class="
                    parseFloat(defaultTicketValue) ===
                    parseFloat(newOrder.product.maxTicketValue) &&
                    'warning-max-value'
                  ">
                    <option v-for="(value,
                      index) in newOrder.product.permittedValues.split(';')" :key="index" :value="value">
                      {{ value }}
                    </option>
                  </select>
                  &nbsp;{{ $t("currency") }}
                </span>
              </td>
            </tr>
          </table>

          <div class="scroll-add-new-beneficiary" v-if="
            this.userRole !== 'FAC' &&
            order_type === true &&
            this.newOrder.type !== 'REN'
            
          ">
            <table v-if="
              this.newOrder.type === 'PAPER' &&
              this.newOrder.personalized &&

              (this.newOrder.product.paper !== 2 ||
                this.newOrder.product.paper !== 4 ||
                this.newOrder.product.paper !== 6)
            " cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
              <tr>
                <td valign="middle">
                  <strong>{{ $t("orderNumberCrt") }}</strong>
                </td>
                <td valign="middle">
                  <strong>{{ $t("beneficiariesTableNameAndSurname") }}</strong>
                </td>
                <td class="priority-3" valign="middle">
                  <strong>{{ $t("beneficiariesTableCNP") }}</strong>
                </td>
                <td class="priority-4" valign="middle">
                  <strong>{{ $t("beneficiariesTableAddress") }}</strong>
                </td>
                <td class="priority-5 numberOfTicket" valign="middle">
                  <strong>{{ $t("beneficiariesTableNoOfTickets") }}</strong>
                </td>
                <td class="priority-5" valingn="middle">
                  <strong>{{ $t("beneficiariesTableTicketValue") }}</strong>
                </td>
                <td valingn="middle">
                  <strong v-html="$t('beneficiariesActionSelect')">
                  </strong>
                </td>
              </tr>
              <tr class="trhover parent background-will-expire">
                <td valign="middle">
                  <a @click="openAddBeneficiaryMobile()" class="detailsOrderMobile display-inline-block priority-show-5">
                    <font-awesome-icon icon="minus-circle" v-if="openAddBeneficiary" />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                </td>
                <td valign="middle">
                  <input type="text" v-model="b_last_name" class="input-add-beneficiary employee" :class="
                    error &&
                    error.code === 'NO_DATA_NAME' &&
                    'box-shadow-error'
                  " @keyup="updateInputCharacters()" />
                </td>
                <td class="priority-3" valign="middle">
                  <input type="number" v-model="b_cnp" class="input-add-beneficiary input-cnp" :class="
                    error &&
                    error.code === 'CNP_INVALID' &&
                    'box-shadow-error'
                  " min="13" max="13" />
                </td>
                <td class="priority-4" valign="middle">
                  <select class="input-add-beneficiary" v-model="b_deliveryAddresses">
                    <option v-for="(delivery, index) in deliveryAddresses" :key="index" :value="delivery.codcom">
                      {{
                        delivery.codcom +
                        " - " +
                        delivery.address.street_name +
                        ", " +
                        delivery.address.locality +
                        (delivery.address.county
                          ? ", " + delivery.address.county
                          : "")
                      }}
                    </option>
                  </select>
                </td>
                <td class="priority-5" valign="middle">
                  <input type="number" v-model="b_vouchers" class="input-add-beneficiary numberOfTicket" :class="
                    error &&
                    error.code === 'NO_DATA_VOUCHERS' &&
                    'box-shadow-error'
                  " />
                </td>
                <td class="priority-5" valign="middle">
                  <span class="currencyinput">
                    <input v-if="newOrder.product.permittedValues === ''" type="text" v-model="b_nominal_value" v-on:blur="
                      b_nominal_value = formatTicketValue(b_nominal_value)
                    " @keyup="
  b_nominal_value = convertValueToDecimalVariable(
    b_nominal_value
  )
" class="input-add-beneficiary amount" :disabled="
  newOrder.product.minTicketValue ===
  newOrder.product.maxTicketValue
" />
                    <select v-else v-model="b_nominal_value">
                      <option v-for="(value,
                        index) in newOrder.product.permittedValues.split(';')" :key="index" :value="value">
                        {{ value }}
                      </option>
                    </select>
                    &nbsp;{{ $t("currency") }}
                  </span>
                </td>
                <td>
                  <a @click="addBeneficiary()" class="custom-font-awesome">
                    <font-awesome-icon icon="user-plus" />
                  </a>
                </td>
              </tr>
              <tr v-show="openAddBeneficiary">
                <td colspan="12">
                  <p class="priority-show-3">
                    <strong>{{ $t("beneficiariesTableCNP") }}: &nbsp;&nbsp;</strong>
                    <input type="number" v-model="b_cnp" class="input-add-beneficiary input-cnp" :class="
                      error &&
                      error.code === 'CNP_INVALID' &&
                      'box-shadow-error'
                    " min="13" max="13" />
                  </p>
                  <p class="priority-show-4">
                    <strong>
                      {{ $t("beneficiariesTableAddress") }}: &nbsp;&nbsp;
                    </strong>
                    <select class="input-add-beneficiary" v-model="b_deliveryAddresses">
                      <option v-for="(delivery, index) in deliveryAddresses" :key="index" :value="delivery.codcom">
                        {{
                          delivery.codcom +
                          " - " +
                          delivery.address.street_name +
                          ", " +
                          delivery.address.locality +
                          (delivery.address.county
                            ? ", " + delivery.address.county
                            : "")
                        }}
                      </option>
                    </select>
                  </p>
                  <p class="priority-show-5">
                    <strong>
                      {{ $t("beneficiariesTableNoOfTickets") }}: &nbsp;&nbsp;
                    </strong>
                    <input type="number" v-model="b_vouchers" class="input-add-beneficiary" :class="
                      error &&
                      error.code === 'NO_DATA_VOUCHERS' &&
                      'box-shadow-error'
                    " />
                  </p>
                  <p class="priority-show-5">
                    <strong>{{ $t("beneficiariesTableTicketValue") }}:</strong>&nbsp;
                    <span class="currencyinput">
                      <input v-if="newOrder.product.permittedValues === ''" type="text" v-model="b_nominal_value"
                        v-on:blur="
                          b_nominal_value = formatTicketValue(b_nominal_value)
                        " @keyup="
  b_nominal_value = convertValueToDecimalVariable(
    b_nominal_value
  )
" class="input-add-beneficiary amount" :disabled="
  newOrder.product.minTicketValue ===
  newOrder.product.maxTicketValue
" />
                      <select v-else v-model="b_nominal_value">
                        <option v-for="(value,
                          index) in newOrder.product.permittedValues.split(';')" :key="index" :value="value">
                          {{ value }}
                        </option>
                      </select>
                      &nbsp;{{ $t("currency") }}
                    </span>
                  </p>
                </td>
              </tr>
              <template v-for="(entry, index) in this.customerBeneficiaries">
                <tr :key="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'">
                  <td valign="middle">
                    <a @click="
                      selectBeneficiaryMobile(index, customerBeneficiaries)
                    " class="detailsOrderMobile display-inline-block priority-show-5">
                      <font-awesome-icon icon="minus-circle" v-if="detailsMobile && entry === selectedBeneficiary" />
                      <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                    </a>
                    <span>&nbsp;&nbsp;{{ index + 1 }}</span>
                  </td>
                  <td valign="middle">
                    <input type="text" v-model="entry.last_name" class="input-add-beneficiary employee"
                      @keyup="updateEntryInputCharacters(entry)" />
                  </td>
                  <td class="priority-3" valign="middle">
                    <input type="number" v-model="entry.cnp"
                      class="input-add-beneficiary input-cnp background-transparent" min="13" max="13" />
                  </td>
                  <td class="priority-4" valign="middle">
                    <span>
                      <select class="input-add-beneficiary background-transparent" v-model="entry.address">
                        <option v-for="(delivery, index) in deliveryAddresses" :key="index" :value="delivery.codcom">
                          {{
                            delivery.codcom +
                            " - " +
                            delivery.address.street_name +
                            ", " +
                            delivery.address.locality +
                            (delivery.address.county
                              ? ", " + delivery.address.county
                              : "")
                          }}
                        </option>
                      </select>
                    </span>
                  </td>
                  <td class="priority-5" valign="middle">
                    <input type="number" class="input-add-beneficiary background-transparent"
                      :id="'vouchers_' + entry.cnp" v-model="entry.vouchers" />
                  </td>
                  <td class="priority-5" valign="middle">
                    <span class="currencyinput" :class="
                      parseFloat(entry.nominal_value) ===
                      parseFloat(newOrder.product.maxTicketValue) &&
                      'warning-max-value'
                    ">
                      <input v-if="newOrder.product.permittedValues === ''" type="text" :class="
                        parseFloat(entry.nominal_value) ===
                        parseFloat(newOrder.product.maxTicketValue) &&
                        'text-white'
                      " v-model="entry.nominal_value" v-on:blur="
  entry.nominal_value = formatTicketValue(
    entry.nominal_value
  )
" @keyup="
  entry.nominal_value = convertValueToDecimalVariable(
    entry.nominal_value
  )
" class="input-add-beneficiary amount" :disabled="
  newOrder.product.minTicketValue ===
  newOrder.product.maxTicketValue
" />
                      <select v-else v-model="entry.nominal_value" :class="
                        parseFloat(entry.nominal_value) ===
                        parseFloat(newOrder.product.maxTicketValue) &&
                        'warning-max-value'
                      ">
                        <option v-for="(value,
                          index) in newOrder.product.permittedValues.split(';')" :key="index" :value="value">
                          {{ value }}
                        </option>
                      </select>
                      &nbsp;{{ $t("currency") }}
                    </span>
                  </td>
                  <td valign="middle">
                    <a v-if="entry.newBeneficiary" @click="deleteBeneficiary(index)" class="custom-font-awesome">
                      <font-awesome-icon icon="user-minus" />
                    </a>
                  </td>
                </tr>
                <tr :key="customerBeneficiaries.length + index" class="child" :class="index % 2 === 0 && 'gri-cell'"
                  v-show="detailsMobile && entry === selectedBeneficiary">
                  <td colspan="12">
                    <p class="priority-show-3">
                      <strong>
                        {{ $t("beneficiariesTableCNP") }} : &nbsp;&nbsp;
                      </strong>
                      <span>
                        <input type="number" v-model="entry.cnp"
                          class="input-add-beneficiary input-cnp background-transparent" min="13" max="13" />
                      </span>
                    </p>
                    <p class="priority-show-4">
                      <strong>
                        {{ $t("beneficiariesTableAddress") }} : &nbsp;&nbsp;
                      </strong>
                      <span>
                        <select class="input-add-beneficiary background-transparent" v-model="entry.address">
                          <option v-for="(delivery, index) in deliveryAddresses" :key="index" :value="delivery.codcom">
                            {{
                              delivery.codcom +
                              " - " +
                              delivery.address.street_name +
                              ", " +
                              delivery.address.locality +
                              (delivery.address.county
                                ? ", " + delivery.address.county
                                : "")
                            }}
                          </option>
                        </select>
                      </span>
                    </p>
                    <p class="priority-show-5">
                      <strong>
                        {{ $t("beneficiariesTableNoOfTickets") }}: &nbsp;&nbsp;
                      </strong>
                      <span>
                        <input type="number" class="input-add-beneficiary background-transparent"
                          :id="'vouchers_' + entry.cnp" v-model="entry.vouchers" />
                      </span>
                    </p>
                    <p class="priority-show-5">
                      <strong>
                        {{ $t("beneficiariesTableTicketValue") }}: &nbsp;&nbsp;
                      </strong>
                      <span class="currencyinput" :class="
                        parseFloat(entry.nominal_value) ===
                        parseFloat(newOrder.product.maxTicketValue) &&
                        'warning-max-value'
                      ">
                        <input v-if="newOrder.product.permittedValues === ''" type="text" :class="
                          parseFloat(entry.nominal_value) ===
                          parseFloat(newOrder.product.maxTicketValue) &&
                          'text-white'
                        " v-model="entry.nominal_value" v-on:blur="
  entry.nominal_value = formatTicketValue(
    entry.nominal_value
  )
" @keyup="
  entry.nominal_value = convertValueToDecimalVariable(
    entry.nominal_value
  )
" class="input-add-beneficiary amount" :disabled="
  newOrder.product.minTicketValue ===
  newOrder.product.maxTicketValue
" />
                        <select v-else v-model="entry.nominal_value" :class="
                          parseFloat(entry.nominal_value) ===
                          parseFloat(newOrder.product.maxTicketValue) &&
                          'warning-max-value'
                        ">
                          <option v-for="(value,
                            index) in newOrder.product.permittedValues.split(
    ';'
  )" :key="index" :value="value">
                            {{ value }}
                          </option>
                        </select>
                        &nbsp;{{ $t("currency") }}
                      </span>
                    </p>
                  </td>
                </tr>
              </template>
              <tr v-if="!this.customerBeneficiaries">
                <td colspan="5">{{ $t("noBeneficiariesInfoMessage") }}</td>
              </tr>
            </table>

            <table v-if="
              this.newOrder.type === 'PAPER' &&
              !this.newOrder.personalized &&
              (this.newOrder.product.paper === 2 ||
                this.newOrder.product.paper === 4 ||
                this.newOrder.product.paper === 6)
            " cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
              <tr>
                <td valign="middle">
                  <strong>{{ $t("orderNumberCrt") }}</strong>
                </td>
                <td v-if="newOrder.grouping === 1" valign="middle">
                  <strong>{{ $t("orderNumberCarnetsPlaceholder") }}</strong>
                </td>
                <td class="priority-3" valign="middle">
                  <strong>{{ $t("beneficiariesTableNoOfTickets") }}</strong>
                </td>
                <td class="priority-4" valingn="middle">
                  <strong>{{ $t("beneficiariesTableTicketValue") }}</strong>
                </td>
                <td class="priority-5" valign="middle">
                  <strong>{{ $t("beneficiariesTableAddress") }}</strong>
                </td>
                <td valingn="middle">
                  <strong v-html="$t('beneficiariesActionSelect')">
                  </strong>
                </td>
              </tr>
              <tr class="trhover parent background-will-expire">
                <td valign="middle">
                  <a @click="openAddBeneficiaryMobile()" class="detailsOrderMobile display-inline-block priority-show-5">
                    <font-awesome-icon icon="minus-circle" v-if="openAddBeneficiary" />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                </td>
                <td v-if="newOrder.grouping === 1" valign="middle">
                  <input type="number" v-model="b_booklets" class="input-add-beneficiary" :class="
                    error &&
                    error.code === 'NO_DATA_BOOKLETS' &&
                    'box-shadow-error'
                  " />
                </td>
                <td class="priority-3" valign="middle">
                  <input type="number" v-model="b_vouchers" class="input-add-beneficiary" :class="
                    error &&
                    (error.code === 'NO_DATA_VOUCHERS' ||
                      error.code === 'MORE_VOUCHERS_CARNETE') &&
                    'box-shadow-error'
                  " />
                </td>
                <td class="priority-4" valign="middle">
                  <span class="currencyinput">
                    <input v-if="newOrder.product.permittedValues === ''" type="text" v-model="b_nominal_value" v-on:blur="
                      b_nominal_value = formatTicketValue(b_nominal_value)
                    " @keyup="
  b_nominal_value = convertValueToDecimalVariable(
    b_nominal_value
  )
" class="input-add-beneficiary amount" :disabled="
  newOrder.product.minTicketValue ===
  newOrder.product.maxTicketValue
" />
                    <select v-else v-model="b_nominal_value">
                      <option v-for="(value,
                        index) in newOrder.product.permittedValues.split(';')" :key="index" :value="value">
                        {{ value }}
                      </option>
                    </select>
                    &nbsp;{{ $t("currency") }}
                  </span>
                </td>
                <td class="priority-5" valign="middle">
                  <select class="input-add-beneficiary" v-model="b_deliveryAddresses">
                    <option v-for="(delivery, index) in deliveryAddresses" :key="index" :value="delivery.codcom">
                      {{
                        delivery.codcom +
                        " - " +
                        delivery.address.street_name +
                        ", " +
                        delivery.address.locality +
                        (delivery.address.county
                          ? ", " + delivery.address.county
                          : "")
                      }}
                    </option>
                  </select>
                </td>
                <td>
                  <a @click="addBeneficiary()" class="custom-font-awesome">
                    <font-awesome-icon icon="user-plus" />
                  </a>
                </td>
              </tr>
              <tr v-show="openAddBeneficiary">
                <td colspan="12">
                  <p class="priority-show-3">
                    <strong>{{ $t("orderNumberTicketsPlaceholder") }}:</strong>&nbsp;
                    <input type="number" v-model="b_vouchers" class="input-add-beneficiary" :class="
                      error &&
                      (error.code === 'NO_DATA_VOUCHERS' ||
                        error.code === 'MORE_VOUCHERS_CARNETE') &&
                      'box-shadow-error'
                    " />
                  </p>
                  <p class="priority-show-4">
                    <strong>{{ $t("beneficiariesTableTicketValue") }}:</strong>&nbsp;
                    <span class="currencyinput">
                      <input v-if="newOrder.product.permittedValues === ''" type="text" v-model="b_nominal_value"
                        v-on:blur="
                          b_nominal_value = formatTicketValue(b_nominal_value)
                        " @keyup="
  b_nominal_value = convertValueToDecimalVariable(
    b_nominal_value
  )
" class="input-add-beneficiary amount" :disabled="
  newOrder.product.minTicketValue ===
  newOrder.product.maxTicketValue
" />
                      <select v-else v-model="b_nominal_value">
                        <option v-for="(value,
                          index) in newOrder.product.permittedValues.split(';')" :key="index" :value="value">
                          {{ value }}
                        </option>
                      </select>
                      &nbsp;{{ $t("currency") }}
                    </span>
                  </p>
                  <p class="priority-show-5">
                    <strong>
                      {{ $t("beneficiariesTableAddress") }}: &nbsp;&nbsp;
                    </strong>
                    <select class="input-add-beneficiary" v-model="b_deliveryAddresses">
                      <option v-for="(delivery, index) in deliveryAddresses" :key="index" :value="delivery.codcom">
                        {{
                          delivery.codcom +
                          " - " +
                          delivery.address.street_name +
                          ", " +
                          delivery.address.locality +
                          (delivery.address.county
                            ? ", " + delivery.address.county
                            : "")
                        }}
                      </option>
                    </select>
                  </p>
                </td>
              </tr>
              <template v-for="(entry, index) in this.customerBeneficiaries">
                <tr :key="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'">
                  <td valign="middle">
                    <a @click="
                      selectBeneficiaryMobile(index, customerBeneficiaries)
                    " class="detailsOrderMobile display-inline-block priority-show-5">
                      <font-awesome-icon icon="minus-circle" v-if="detailsMobile && entry === selectedBeneficiary" />
                      <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                    </a>
                    <span>&nbsp;&nbsp;{{ index + 1 }}</span>
                  </td>
                  <td v-if="newOrder.grouping === 1" valign="middle">
                    <input type="number" v-model="entry.cards" class="input-add-beneficiary background-transparent" />
                  </td>
                  <td class="priority-3" valign="middle">
                    <input type="number" v-model="entry.vouchers" :id="'vouchers_' + entry.cnp"
                      class="input-add-beneficiary background-transparent" />
                  </td>
                  <td class="priority-4" valign="middle">
                    <span class="currencyinput" :class="
                      parseFloat(entry.nominal_value) ===
                      parseFloat(newOrder.product.maxTicketValue) &&
                      'warning-max-value'
                    ">
                      <input v-if="newOrder.product.permittedValues === ''" type="text" :class="
                        parseFloat(entry.nominal_value) ===
                        parseFloat(newOrder.product.maxTicketValue) &&
                        'text-white'
                      " v-model="entry.nominal_value" v-on:blur="
  entry.nominal_value = formatTicketValue(
    entry.nominal_value
  )
" @keyup="
  entry.nominal_value = convertValueToDecimalVariable(
    entry.nominal_value
  )
" class="input-add-beneficiary amount" :disabled="
  newOrder.product.minTicketValue ===
  newOrder.product.maxTicketValue
" />
                      <select v-else v-model="entry.nominal_value" :class="
                        parseFloat(entry.nominal_value) ===
                        parseFloat(newOrder.product.maxTicketValue) &&
                        'warning-max-value'
                      ">
                        <option v-for="(value,
                          index) in newOrder.product.permittedValues.split(';')" :key="index" :value="value">
                          {{ value }}
                        </option>
                      </select>
                      &nbsp;{{ $t("currency") }}
                    </span>
                  </td>
                  <td class="priority-5" valign="middle">
                    <select class="input-add-beneficiary background-transparent" v-model="entry.address">
                      <option v-for="(delivery, index) in deliveryAddresses" :key="index" :value="delivery.codcom">
                        {{
                          delivery.codcom +
                          " - " +
                          delivery.address.street_name +
                          ", " +
                          delivery.address.locality +
                          (delivery.address.county
                            ? ", " + delivery.address.county
                            : "")
                        }}
                      </option>
                    </select>
                  </td>
                  <td valign="middle">
                    <a v-if="entry.newBeneficiary" @click="deleteBeneficiary(index)" class="custom-font-awesome">
                      <font-awesome-icon icon="user-minus" />
                    </a>
                  </td>
                </tr>
                <tr :key="customerBeneficiaries.length + index" class="child" :class="index % 2 === 0 && 'gri-cell'"
                  v-show="detailsMobile && entry === selectedBeneficiary">
                  <td colspan="12">
                    <p class="priority-show-3">
                      <strong>
                        {{ $t("orderNumberTicketsPlaceholder") }}: &nbsp;&nbsp;
                      </strong>
                      <span>
                        {{ entry.vouchers }}&nbsp;&nbsp;{{ $t("currency") }}
                      </span>
                    </p>
                    <p class="priority-show-4">
                      <strong>
                        {{ $t("beneficiariesTableTicketValue") }}: &nbsp;&nbsp;
                      </strong>
                      <span class="currencyinput" :class="
                        parseFloat(entry.nominal_value) ===
                        parseFloat(newOrder.product.maxTicketValue) &&
                        'warning-max-value'
                      ">
                        <input v-if="newOrder.product.permittedValues === ''" type="text" :class="
                          parseFloat(entry.nominal_value) ===
                          parseFloat(newOrder.product.maxTicketValue) &&
                          'text-white'
                        " v-model="entry.nominal_value" v-on:blur="
  entry.nominal_value = formatTicketValue(
    entry.nominal_value
  )
" @keyup="
  entry.nominal_value = convertValueToDecimalVariable(
    entry.nominal_value
  )
" class="input-add-beneficiary amount" :disabled="
  newOrder.product.minTicketValue ===
  newOrder.product.maxTicketValue
" />
                        <select v-else v-model="entry.nominal_value" :class="
                          parseFloat(entry.nominal_value) ===
                          parseFloat(newOrder.product.maxTicketValue) &&
                          'warning-max-value'
                        ">
                          <option v-for="(value,
                            index) in newOrder.product.permittedValues.split(
    ';'
  )" :key="index" :value="value">
                            {{ value }}
                          </option>
                        </select>
                        &nbsp;{{ $t("currency") }}
                      </span>
                    </p>
                    <p class="priority-show-5">
                      <strong>
                        {{ $t("beneficiariesTableAddress") }} : &nbsp;&nbsp;
                      </strong>
                      <span>{{ getAddressDisplayName(entry.address) }}</span>
                    </p>
                  </td>
                </tr>
              </template>
              <tr v-if="!this.customerBeneficiaries">
                <td colspan="5">{{ $t("noBeneficiariesInfoMessage") }}</td>
              </tr>
            </table>

            <table v-if="
              this.newOrder.type === 'NEWCR' || this.newOrder.type === 'NEW'
            " cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
              <tr>
                <td valign="middle">
                  <strong>{{ $t("orderNumberCrt") }}</strong>
                </td>
                <td valign="middle">
                  <strong>{{ $t("beneficiariesTableName") }}</strong>
                </td>
                <td class="priority-2" valign="middle">
                  <strong>{{ $t("beneficiariesTableSurname") }}</strong>
                </td>
                <td class="priority-3" valign="middle">
                  <strong>{{ $t("beneficiariesTableCNP") }}</strong>
                </td>
                <td v-if="this.newOrder.type !== 'NEWCR' && orderProductVirtual" class="priority-4" valign="middle">
                  <strong>{{ $t("beneficiariesTableCardType") }}</strong>
                </td>
                <td v-if="this.newOrder.type !== 'NEWCR'" class="priority-4" valign="middle">
                  <strong>{{ $t("beneficiariesTableAddress") }}</strong>
                </td>
                <td v-if="this.newOrder.type !== 'NEWCR'" class="priority-5" valign="middle">
                  <strong>{{ $t("beneficiariesTableMobile") }}</strong>
                </td>
                <td v-if="this.newOrder.type !== 'NEWCR'" class="priority-5" valign="middle">
                  <strong>{{ $t("beneficiariesTableEmail") }}</strong>
                </td>
                <td v-if="this.newOrder.type === 'NEWCR'" class="priority-5" valign="middle">
                  <strong>{{ $t("beneficiariesTableNoOfTickets") }}</strong>
                </td>
                <td v-if="this.newOrder.type === 'NEWCR'" class="priority-5" valign="middle">
                  <strong>{{ $t("beneficiariesTableTicketValue") }}</strong>
                </td>
                <td v-if="this.newOrder.type !== 'NEWCR'" valingn="middle">
                  <strong v-html="$t('beneficiariesActionSelect')">
                  </strong>
                </td>
              </tr>
              <tr v-if="this.newOrder.type !== 'NEWCR'" class="trhover parent background-will-expire">
                <td valign="middle">
                  <a @click="openAddBeneficiaryMobile()" class="detailsOrderMobile display-inline-block priority-show-5">
                    <font-awesome-icon icon="minus-circle" v-if="openAddBeneficiary" />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                </td>
                <td valign="middle">
                  <input type="text" v-model="b_last_name" class="input-add-beneficiary"
                    @keyup="updateInputCharacters()" />
                </td>
                <td class="priority-2" valign="middle">
                  <input type="text" v-model="b_first_name" class="input-add-beneficiary"
                    @keyup="updateInputCharacters()" />
                </td>
                <td class="priority-3" valign="middle">
                  <input type="number" v-model="b_cnp" class="input-add-beneficiary input-cnp" min="13" max="13" />
                </td>
                <td class="priority-4" valign="middle" v-if="orderProductVirtual">
                  <select class="input-add-beneficiary" v-model="b_virtual" >
                    <option disabled selected>
                        {{ $t("cardsAction_CHOOSE") }}
                      </option>
                      <option value="true">{{ $t('beneficiariesTableCardTypeVirtual') }} </option>
                      <option value="false">{{ $t('beneficiariesTableCardTypePhysical') }} </option>
                    </select>
                </td>
                <td  class="priority-4" valign="middle">
                  <select class="input-add-beneficiary" v-model="b_deliveryAddresses">
                    <option v-for="(delivery, index) in deliveryAddresses" :key="index" :value="delivery.codcom">
                      {{
                        delivery.codcom +
                        " - " +
                        delivery.address.street_name +
                        ", " +
                        delivery.address.locality +
                        (delivery.address.county
                          ? ", " + delivery.address.county
                          : "")
                      }}
                    </option>
                  </select>
                </td>
                <td v-if="this.newOrder.type !== 'NEWCR'" class="priority-5" valign="middle">
                  <input type="text" v-model="b_mobileNumber" class="input-add-beneficiary" @keyup="
                    b_mobileNumber = mobilePhoneOnlyNumbers(b_mobileNumber)
                  " />
                </td>
                <td v-if="this.newOrder.type !== 'NEWCR'" class="priority-5" valign="middle">
                  <form @submit.prevent="
                    {
                      return false;
                    }
                  ">
                    <input type="text" v-model="b_email" autocomplete="off" class="input-add-beneficiary" />
                  </form>
                </td>
                <td>
                  <a @click="addBeneficiary()" class="custom-font-awesome">
                    <font-awesome-icon icon="user-plus" />
                  </a>
                </td>
              </tr>
              <tr v-if="this.newOrder.type !== 'NEWCR'" v-show="openAddBeneficiary">
                <td colspan="12">
                  <p class="priority-show-2">
                    <strong>
                      {{ $t("beneficiariesTableSurname") }}: &nbsp;&nbsp;
                    </strong>
                    <input type="text" v-model="b_first_name" class="input-add-beneficiary"
                      @keyup="updateInputCharacters()" />
                  </p>
                  <p class="priority-show-3">
                    <strong>{{ $t("beneficiariesTableCNP") }}: &nbsp;&nbsp;</strong>
                    <input type="text" v-model="b_cnp" class="input-add-beneficiary input-cnp" min="13" max="13" />
                  </p>
                  <p v-if="this.newOrder.type !== 'NEWCR' && orderProductVirtual" class="priority-show-4" >
                    <strong>
                      {{ $t("beneficiariesTableCardType") }}: &nbsp;&nbsp;
                    </strong>
                    <select class="input-add-beneficiary" v-model="b_virtual">
                      <option disabled selected>
                        {{ $t("cardsAction_CHOOSE") }}
                      </option>
                      <option value="true">{{ $t('beneficiariesTableCardTypeVirtual') }} </option>
                      <option value="false">{{ $t('beneficiariesTableCardTypePhysical') }} </option>
                    </select>
                  </p>
                  <p v-if="this.newOrder.type !== 'NEWCR'" class="priority-show-4">
                    <strong>
                      {{ $t("beneficiariesTableAddress") }}: &nbsp;&nbsp;
                    </strong>
                    <select class="input-add-beneficiary" v-model="b_deliveryAddresses">
                      <option disabled selected>
                        {{ $t("cardsAction_CHOOSE") }}
                      </option>
                      <option v-for="(delivery, index) in deliveryAddresses" :key="index" :value="delivery.codcom">
                        {{
                          delivery.codcom +
                          " - " +
                          delivery.address.street_name +
                          ", " +
                          delivery.address.locality +
                          (delivery.address.county
                            ? ", " + delivery.address.county
                            : "")
                        }}
                      </option>
                    </select>
                  </p>
                  <p v-if="this.newOrder.type !== 'NEWCR'" class="priority-show-5">
                    <strong>{{ $t("beneficiariesTableMobile") }}:
                      &nbsp;&nbsp;</strong>
                    <input type="text" v-model="b_mobileNumber" class="input-add-beneficiary" />
                  </p>
                  <p v-if="this.newOrder.type !== 'NEWCR'" class="priority-show-5">
                    <strong>{{ $t("beneficiariesTableEmail") }}: &nbsp;&nbsp;</strong>
                      <input type="text" v-model="b_email" autocomplete="off" class="input-add-beneficiary" />
                  </p>
                </td>
              </tr>
              <template v-for="(entry, index) in this.customerBeneficiaries">
                <tr :key="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'">
                  <td valign="middle">
                    <a @click="
                      selectBeneficiaryMobile(index, customerBeneficiaries)
                    " class="detailsOrderMobile display-inline-block priority-show-5">
                      <font-awesome-icon icon="minus-circle" v-if="detailsMobile && entry === selectedBeneficiary" />
                      <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                    </a>
                    <span>&nbsp;&nbsp;{{ index + 1 }}</span>
                  </td>
                  <td valign="middle">
                    <input v-if="newOrder.type === 'NEW'" type="text" v-model="entry.last_name"
                      class="input-add-beneficiary" @keyup="updateEntryInputCharacters(entry)" />
                    <span v-else>{{ entry.last_name }}</span>
                  </td>
                  <td class="priority-2" valign="middle">
                    <input v-if="newOrder.type === 'NEW'" type="text" v-model="entry.first_name"
                      class="input-add-beneficiary" @keyup="updateEntryInputCharacters(entry)" />
                    <span v-else>{{ entry.first_name }}</span>
                  </td>
                  <td class="priority-3" valign="middle">
                    <input v-if="newOrder.type === 'NEW'" type="text" v-model="entry.cnp"
                      class="input-add-beneficiary input-cnp" min="13" max="13" />
                    <span v-else>{{ entry.cnp }}</span>
                  </td>
                  <td v-if="newOrder.type !== 'NEWCR' && orderProductVirtual" class="priority-4" valign="middle">
                    <select class="input-add-beneficiary" v-model="entry.card_virtual">
                      <option value="true">{{ $t('beneficiariesTableCardTypeVirtual') }} </option>
                      <option value="false">{{ $t('beneficiariesTableCardTypePhysical') }} </option>
                    </select>
                    </td>
                  <td v-if="newOrder.type !== 'NEWCR'" class="priority-4" valign="middle">
                    <select class="input-add-beneficiary background-transparent" v-model="entry.address">
                      <option v-for="(delivery, index) in deliveryAddresses" :key="index" :value="delivery.codcom">
                        {{
                          delivery.codcom +
                          " - " +
                          delivery.address.street_name +
                          ", " +
                          delivery.address.locality +
                          (delivery.address.county
                            ? ", " + delivery.address.county
                            : "")
                        }}
                      </option>
                    </select>
                  </td>
                  <td v-if="newOrder.type !== 'NEWCR'" class="priority-5" valign="middle">
                    <form @submit.prevent="
                      {
                        return false;
                      }
                    ">
                      <input type="text" v-model="entry.phone" class="input-add-beneficiary" />
                    </form>
                  </td>
                  <td v-if="newOrder.type !== 'NEWCR'" class="priority-5" valign="middle">
                    <form @submit.prevent="
                      {
                        return false;
                      }
                    ">
                      <input type="text" v-model="entry.email" class="input-add-beneficiary" />
                    </form>
                  </td>
                  <td v-if="newOrder.type === 'NEWCR'" class="priority-5" valign="middle">
                    <span class="currencyinput">
                      <input type="number" class="input-add-beneficiary" :id="'vouchers_' + entry.cnp"
                        v-model="entry.vouchers" />
                    </span>
                  </td>
                  <td v-if="newOrder.type === 'NEWCR'" class="priority-5" valign="middle">
                    <span class="currencyinput" :class="
                      parseFloat(entry.nominal_value) ===
                      parseFloat(newOrder.product.maxTicketValue) &&
                      'warning-max-value'
                    ">
                      <input v-if="newOrder.product.permittedValues === ''" type="text" :class="
                        parseFloat(entry.nominal_value) ===
                        parseFloat(newOrder.product.maxTicketValue) &&
                        'text-white'
                      " v-model="entry.nominal_value" v-on:blur="formatTicketValueFromAPI(entry)"
                        @keyup="convertValueToDecimalVariableFromAPI(entry)" class="input-add-beneficiary amount" />
                      <select v-else v-model="entry.nominal_value" :class="
                        parseFloat(entry.nominal_value) ===
                        parseFloat(newOrder.product.maxTicketValue) &&
                        'warning-max-value'
                      ">
                        <option v-for="(value,
                          index) in newOrder.product.permittedValues.split(';')" :key="index" :value="value">
                          {{ value }}
                        </option>
                      </select>
                      &nbsp;{{ $t("currency") }}
                    </span>
                  </td>
                  <td v-if="newOrder.type !== 'NEWCR'" valign="middle">
                    <a v-if="entry.newBeneficiary" @click="deleteBeneficiary(index)" class="custom-font-awesome">
                      <font-awesome-icon icon="user-minus" />
                    </a>
                  </td>
                </tr>
                <tr :key="customerBeneficiaries.length + index" class="child" :class="index % 2 === 0 && 'gri-cell'"
                  v-show="detailsMobile && entry === selectedBeneficiary">
                  <td colspan="12">
                    <p class="priority-show-2">
                      <strong>
                        {{ $t("beneficiariesTableSurname") }} : &nbsp;&nbsp;
                      </strong>
                      <input v-if="newOrder.type === 'NEW'" type="text" v-model="entry.first_name"
                        class="input-add-beneficiary" @keyup="updateEntryInputCharacters(entry)" />
                      <span v-else>{{ entry.first_name }}</span>
                    </p>
                    <p class="priority-show-3">
                      <strong>
                        {{ $t("beneficiariesTableCNP") }} : &nbsp;&nbsp;
                      </strong>
                      <input v-if="newOrder.type === 'NEW'" type="text" v-model="entry.cnp"
                        class="input-add-beneficiary input-cnp" min="13" max="13" />
                      <span v-else>{{ entry.cnp }}</span>
                    </p>
                    <p v-if="newOrder.type !== 'NEWCR' && orderProductVirtual" class="priority-show-4">
                      <strong>
                        {{ $t("beneficiariesTableCardType") }}: &nbsp;&nbsp;
                      </strong>
                      <select class="input-add-beneficiary" v-model="entry.card_virtual">
                        <option value="true">{{ $t('beneficiariesTableCardTypeVirtual') }} </option>
                        <option value="false">{{ $t('beneficiariesTableCardTypePhysical') }} </option>
                      </select>
                    </p>
                    <p v-if="newOrder.type !== 'NEWCR'" class="priority-show-4">
                      <strong>
                        {{ $t("beneficiariesTableAddress") }} : &nbsp;&nbsp;
                      </strong>
                      <select class="input-add-beneficiary background-transparent" v-model="entry.address">
                        <option v-for="(delivery, index) in deliveryAddresses" :key="index" :value="delivery.codcom">
                          {{
                            delivery.codcom +
                            " - " +
                            delivery.address.street_name +
                            ", " +
                            delivery.address.locality +
                            (delivery.address.county
                              ? ", " + delivery.address.county
                              : "")
                          }}
                        </option>
                      </select>
                    </p>
                    <p v-if="newOrder.type !== 'NEWCR'" class="priority-show-5">
                      <strong>
                        {{ $t("beneficiariesTableMobile") }} : &nbsp;&nbsp;
                      </strong>
                      <input type="text" v-model="entry.phone" class="input-add-beneficiary" />
                    </p>
                    <p v-if="newOrder.type !== 'NEWCR'" class="priority-show-5">
                      <strong>
                        {{ $t("beneficiariesTableEmail") }} : &nbsp;&nbsp;
                      </strong>
                      <input type="text" v-model="entry.email" class="input-add-beneficiary" />
                    </p>
                    <p v-if="newOrder.type === 'NEWCR'" class="priority-show-5">
                      <strong>
                        {{ $t("beneficiariesTableNoOfTickets") }} : &nbsp;&nbsp;
                      </strong>
                      <span class="currencyinput">
                        <input type="number" class="input-add-beneficiary" :id="'vouchers_' + entry.cnp"
                          v-model="entry.vouchers" />
                      </span>
                    </p>
                    <p v-if="newOrder.type === 'NEWCR'" class="priority-show-5">
                      <strong>
                        {{ $t("beneficiariesTableTicketValue") }} : &nbsp;&nbsp;
                      </strong>
                      <span class="currencyinput" :class="
                        parseFloat(entry.nominal_value) ===
                        parseFloat(newOrder.product.maxTicketValue) &&
                        'warning-max-value'
                      ">
                        <input v-if="newOrder.product.permittedValues === ''" type="text" :class="
                          parseFloat(entry.nominal_value) ===
                          parseFloat(newOrder.product.maxTicketValue) &&
                          'text-white'
                        " v-model="entry.nominal_value" v-on:blur="formatTicketValueFromAPI(entry)"
                          @keyup="convertValueToDecimalVariableFromAPI(entry)" class="input-add-beneficiary amount" />
                        <select v-else v-model="entry.nominal_value" :class="
                          parseFloat(entry.nominal_value) ===
                          parseFloat(newOrder.product.maxTicketValue) &&
                          'warning-max-value'
                        ">
                          <option v-for="(value,
                            index) in newOrder.product.permittedValues.split(
    ';'
  )" :key="index" :value="value">
                            {{ value }}
                          </option>
                        </select>
                        &nbsp;{{ $t("currency") }}
                      </span>
                    </p>
                  </td>
                </tr>
              </template>
              <tr v-if="
                this.customerBeneficiaries === null &&
                this.newOrder.type === 'NEWCR'
              ">
                <td colspan="12">
                  <div class="loading">
                    <img src="@/assets/images/loading.gif" />
                  </div>
                </td>
              </tr>
              <tr v-if="
                !this.customerBeneficiaries && this.newOrder.type !== 'NEWCR'
              ">
                <td colspan="5">{{ $t("noBeneficiariesInfoMessage") }}</td>
              </tr>
            </table>
          </div>
        </div>

        <br />
      </div>

      <br />
      <p v-if="this.newOrder.type === 'PAPER'">
        <strong>{{ $t("orderPersonalizeVouchersTitle") }}</strong>
      </p>
      <div class="container-alb" v-if="this.newOrder.type === 'PAPER'">
        <table cellpadding="20" cellspacing="0" width="95%" class="hover-table mobile-table" align="center">
          <tr v-if="productLogo">
            <td>
              <strong>{{ $t("orderPersonalizedIconLabel") }}</strong>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </td>
            <td>
              <input type="checkbox" class="checkbox-custom" v-model="newOrder.logo" />
              <span>{{ $t("orderPersonalizedPrintIconLabel") }}&nbsp;&nbsp;</span>
              <a @click="showModalPopup('modal_popup_openLogo', true)" align="right"
                class="status-background background-will-expire ml-2">
                &nbsp;&nbsp;{{
                  $t("orderPersonalizedViewIconLabel")
                }}&nbsp;&nbsp;
              </a>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <span class="msg-label">
                <strong>{{ $t("orderPersonalizedMessageLabel") }}</strong>
              </span>
            </td>
            <td align="left" width="75%" valign="top">
              <form @submit.prevent="
                {
                  return false;
                }
              ">
                <input type="text" v-model="newOrder.text1" maxlength="30" class="input-msg" />
                <input type="text" v-model="newOrder.text2" maxlength="35" class="input-msg" />
              </form>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <strong class="table-label margin-top-5">
                {{ $t("orderPersonalizedGroupingLabel") }}
              </strong>
            </td>
            <td>
              <a v-if="packageProduct.group_planse === 'true'" class="status-background background-grey margin-right-5"
                :class="newOrder.grouping === 3 && 'background-will-expire'" @click="newOrder.grouping = 3">{{
                  $t("orderPersonalizedGroupingPlanse") }}</a>
              <a v-if="packageProduct.group_pachet === 'true'" class="status-background background-grey margin-right-5"
                :class="newOrder.grouping === 2 && 'background-will-expire'" @click="newOrder.grouping = 2">{{
                  $t("orderPersonalizedGroupingPachet") }}</a>
              <a v-if="packageProduct.group_carnet === 'true'" class="status-background background-grey margin-right-5"
                :class="newOrder.grouping === 1 && 'background-will-expire'" @click="newOrder.grouping = 1">{{
                  $t("orderPersonalizedGroupingCarnet") }}</a>
            </td>
          </tr>
        </table>
      </div>
      <br />
      <p v-if="this.newOrder.type === 'PAPER'">
        <strong>{{ $t("orderDeliveryTitle") }}</strong>
      </p>
      <div v-if="this.newOrder.type === 'PAPER'" class="container-alb">
        <table cellpadding="20" cellspacing="0" width="95%" class="hover-table mobile-table" align="center">
          <tr>
            <td valign="top">
              <input type="radio" class="radio-custom" :value="false" id="zero" v-model="newOrder.delivery_pickup" />
              <label for="zero">
                <strong>{{ $t("orderDeliveryCourier") }}</strong>
              </label>
              &nbsp;&nbsp;
              <input v-if="!newOrder.delivery_pickup" type="checkbox" id="checkbox" class="ml-2 checkbox-custom"
                v-model="newOrder.saturday_delivery" />
              <label v-if="!newOrder.delivery_pickup" for="checkbox">{{
                $t("orderDeliverySaturday")
              }}</label>
              <br />
              <input type="radio" class="radio-custom" :value="true" id="one" v-model="newOrder.delivery_pickup" />
              <label for="one">
                <strong>{{ $t("orderDeliveryPickup") }}</strong>
              </label>
              <br />
            </td>
          </tr>
        </table>
      </div>

      <a v-if="
        this.userRole !== 'FAC' &&
        order_type &&
        this.newOrder.type === 'NEW'" @click="addBeneficiary()" class="notification-action-small step3 cursor-pointer">
        <font-awesome-icon icon="user-plus" />{{ "&nbsp;" + $t('beneficiariesActionSelect') }}
      </a>

      <a href="#" class="notification-action-small step3" @click="showModalPopupVirtual()">{{ $t("nextStepButtonLabel") }}</a>
    </div>

    <!-- order step 4, summary -->
    <div v-if="this.orderStep === 4">
      <p>
        <strong>{{ $t("details") }}</strong>
      </p>
      <div class="container-alb">
        <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
          <tr class="trhover">
            <td>
              <strong>{{ $t("orderDetailsAddressLabel") }}</strong>
              {{ customer.address }}
            </td>
          </tr>
          <tr class="gri-cell trhover">
            <td>
              <strong>{{ $t("orderDetailsProductLabel") }}</strong>
              {{ newOrder.product.name }}
            </td>
          </tr>
          <tr class="trhover" v-if="
            this.newOrder.type === 'NEW' ||
            this.newOrder.type === 'NEWCR' ||
            this.newOrder.type === 'REN'
          ">
            <td>
              <strong>{{ $t("orderDetailsNoCardsLabel") }}</strong>
              {{ totalDetails }}
            </td>
          </tr>
          <tr class="gri-cell trhover" v-if="this.newOrder.type === 'NEWCR'">
            <td>
              <strong>{{ $t("orderDetailsNoTicketsLabel") }}</strong>
              {{ confirmationOrder.total_vouchers }}
            </td>
          </tr>
          <tr class="trhover" v-if="this.newOrder.type !== 'NEW'">
            <td>
              <strong>{{ $t("orderDetailsNominalValueLabel") }}</strong>
              {{ confirmationOrder.total_nominal_value }} {{ $t("currency") }}
            </td>
          </tr>
          <tr class="gri-cell trhover" v-if="
            this.newOrder.type === 'PAPER' &&
            this.confirmationOrder.message1 !== ''
          ">
            <td>
              <strong>{{ $t("orderDetailsMessage1") }}</strong>
              {{ confirmationOrder.message1 }}
            </td>
          </tr>
          <tr class="trhover" v-if="
            this.newOrder.type === 'PAPER' &&
            this.confirmationOrder.message2 !== ''
          ">
            <td>
              <strong>{{ $t("orderDetailsMessage2") }}</strong>
              {{ confirmationOrder.message2 }}
            </td>
          </tr>
        </table>
      </div>

      <a v-if="this.userRole === 'FAC'" href="#" class="notification-action-small" @click="confirmOrder()">{{
        $t("finalStepButtonLabel") }}</a>

      <div class="container-alb moverflow border-color-11 border-solid-3" v-if="
        this.newOrder.type === 'NEWCR' &&
        this.confirmationOrder.details !== null &&
        this.confirmationOrder.details.length > 0
      ">
        <table cellpadding="10" cellspacing="0" width="95%" align="center">
          <tr>
            <td>
              <h3>{{ $t("beneficiariesNameNew") }}</h3>
            </td>
          </tr>
        </table>

        <div class="scroll-content">
          <table cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
            <tr>
              <th valign="middle">
                <strong>{{ $t("beneficiariesTableName") }}</strong>
              </th>
              <th valign="middle">
                <strong>{{ $t("beneficiariesTableSurname") }}</strong>
              </th>
              <th class="priority-0" valign="middle">
                <strong>{{ $t("beneficiariesTableCNP") }}</strong>
              </th>
              <th class="priority-1" valign="middle" v-if="orderProductVirtual">
                <strong>{{ $t("beneficiariesTableCardType") }}</strong>&nbsp;
              </th>
              <th class="priority-1" valign="middle">
                <strong>{{ $t("beneficiariesTableAddress") }}</strong>&nbsp;
              </th>
              <th class="priority-4" valign="middle">
                <strong>{{ $t("beneficiariesTableMobile") }}</strong>
              </th>
              <th class="priority-5" valign="middle">
                <strong>{{ $t("beneficiariesTableEmail") }}</strong>
              </th>
              <th class="priority-2" valign="middle">
                <strong>{{ $t("beneficiariesTableNoOfTickets") }}</strong>&nbsp;
              </th>
              <th class="priority-3" valign="middle">
                <strong>{{ $t("beneficiariesTableTicketValue") }}</strong>
              </th>
              <th class="priority-4" valign="middle">
                <strong>{{ $t("beneficiariesTableTotalValue") }}</strong>&nbsp;
              </th>
            </tr>
            <template v-for="(detail, index) in this.confirmationOrder.details">
              <tr :key="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'">
                <td>
                  <a class="detailsOrderMobile display-inline-block" :class="'priority-show-4'" @click="
                    selectBeneficiaryMobile(
                      index,
                      this.confirmationOrder.details
                    )
                  ">
                    <font-awesome-icon icon="minus-circle" v-if="detailsMobile && detail === selectedBeneficiary" />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                  <input type="text" v-model="detail.last_name" class="input-add-beneficiary"
                    @keyup="updateInputCharacters()" />
                  <!-- <span>
                  {{ detail.last_name }}</span> -->
                </td>
                <td valign="middle">
                  <input type="text" v-model="detail.first_name" class="input-add-beneficiary"
                    @keyup="updateInputCharacters()" />

                  <!-- {{ detail.first_name }} -->
                </td>
                <td class="priority-0" valign="middle">{{ detail.cnp }}</td>
                
                <td class="priority-0" valign="middle" v-if="orderProductVirtual">
                    <select class="input-add-beneficiary" v-model="detail.card_virtual">
                      <option value="true">{{ $t('beneficiariesTableCardTypeVirtual') }} </option>
                      <option value="false">{{ $t('beneficiariesTableCardTypePhysical') }} </option>
                    </select>
                  </td>
                <td class="priority-1" valign="middle">
                  <span>
                    <select class="input-add-beneficiary background-transparent" v-model="detail.address" required>
                      <option v-for="(delivery, index) in deliveryAddresses" :key="index" :value="delivery.codcom">
                        {{
                          delivery.codcom +
                          " - " +
                          delivery.address.street_name +
                          ", " +
                          delivery.address.locality +
                          (delivery.address.county
                            ? ", " + delivery.address.county
                            : "")
                        }}
                      </option>
                    </select>
                  </span>
                </td>
                <td class="priority-4" valign="middle">
                  <form @submit.prevent="
                    {
                      return false;
                    }
                  ">
                    <input type="text" v-model="detail.phone" class="input-add-beneficiary" />
                  </form>
                </td>
                <td class="priority-5" valign="middle">
                  <form @submit.prevent="
                    {
                      return false;
                    }
                  ">
                    <input type="text" v-model="detail.email" class="input-add-beneficiary" />
                  </form>
                </td>
                <td class="priority-2" valign="middle">
                  {{ detail.vouchers }}
                </td>
                <td class="priority-3" valign="middle">
                  {{ detail.nominal_value }}
                </td>
                <td class="priority-4" valign="middle">
                  {{ detail.total_nominal_value }}
                </td>
              </tr>
              <tr :key="index + confirmationOrder.details.length" class="child" :class="index % 2 === 0 && 'gri-cell'"
                v-show="detailsMobile && detail === selectedBeneficiary">
                <td colspan="12">
                  <p class="priority-show-0">
                    <strong>{{ $t("beneficiariesTableCNP") }} : &nbsp;&nbsp;</strong>
                    <span>{{ detail.cnp }}</span>
                  </p>
                  <p  class="priority-show-4"  v-if="orderProductVirtual">
                      <strong>
                        {{ $t("beneficiariesTableCardType") }}: &nbsp;&nbsp;
                      </strong>
                      <select class="input-add-beneficiary" v-model="detail.card_virtual">
                        <option value="true">{{ $t('beneficiariesTableCardTypeVirtual') }} </option>
                        <option value="false">{{ $t('beneficiariesTableCardTypePhysical') }} </option>
                      </select>
                    </p>

                  <p class="priority-show-1">
                    <strong>
                      {{ $t("beneficiariesTableAddress") }} : &nbsp;&nbsp;
                    </strong>
                    <span>
                      <select class="input-add-beneficiary background-transparent" v-model="detail.address" required>
                        <option v-for="(delivery, index) in deliveryAddresses" :key="index" :value="delivery.codcom">
                          {{
                            delivery.codcom +
                            " - " +
                            delivery.address.street_name +
                            ", " +
                            delivery.address.locality +
                            (delivery.address.county
                              ? ", " + delivery.address.county
                              : "")
                          }}
                        </option>
                      </select>
                    </span>
                  </p>
                  <p class="priority-show-4">
                    <strong>
                      {{ $t("beneficiariesTableMobile") }} : &nbsp;&nbsp;
                    </strong>
                    <span>
                      <form @submit.prevent="
                        {
                          return false;
                        }
                      ">
                        <input type="text" v-model="detail.phone" class="input-add-beneficiary" />
                      </form>
                    </span>
                  </p>
                  <p class="priority-show-5">
                    <strong>
                      {{ $t("beneficiariesTableEmail") }} : &nbsp;&nbsp;
                    </strong>
                    <span>
                      <form @submit.prevent="
                        {
                          return false;
                        }
                      ">
                        <input type="text" v-model="detail.email" class="input-add-beneficiary" />
                      </form>
                    </span>
                  </p>
                  <p class="priority-show-2">
                    <strong>
                      {{ $t("beneficiariesTableNoOfTickets") }} : &nbsp;&nbsp;
                    </strong>
                    <span>{{ detail.vouchers }}</span>
                  </p>
                  <p class="priority-show-3">
                    <strong>
                      {{ $t("beneficiariesTableTicketValue") }} : &nbsp;&nbsp;
                    </strong>
                    <span>{{ detail.nominal_value }}</span>
                  </p>
                  <p class="priority-show-4">
                    <strong>
                      {{ $t("beneficiariesTableTotalValue") }} : &nbsp;&nbsp;
                    </strong>
                    <span>{{ detail.total_nominal_value }}</span>
                  </p>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>

      <div class="container-alb moverflow">
        <table cellpadding="10" cellspacing="0" width="95%" align="center">
          <tr>
            <td>
              <h3>{{ $t("beneficiariesName") }}</h3>
            </td>
          </tr>
        </table>

        <div class="scroll-content">
          <table v-if="
            this.newOrder.type === 'PAPER' &&
            !this.newOrder.personalized &&
            (this.newOrder.product.paper === 2 ||
              this.newOrder.product.paper === 4)
          " cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
            <tr>
              <td v-if="newOrder.grouping === 1" valign="middle">
                <strong>{{ $t("orderNumberCarnetsPlaceholder") }}</strong>
              </td>
              <td class="priority-3" valign="middle">
                <strong>{{ $t("beneficiariesTableNoOfTickets") }}</strong>
              </td>
              <td class="priority-4" valingn="middle">
                <strong>{{ $t("beneficiariesTableTicketValue") }}</strong>
              </td>
              <td class="priority-5" valign="middle">
                <strong>{{ $t("beneficiariesTableAddress") }}</strong>
              </td>
            </tr>
            <template v-for="(detail, index) in this.confirmationOrder.detailsPag">
              <tr :key="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'">
                <td valign="middle">
                  <a @click="
                    selectBeneficiaryMobile(
                      index,
                      confirmationOrder.detailsPag
                    )
                  " class="detailsOrderMobile display-inline-block priority-show-5">
                    <font-awesome-icon icon="minus-circle" v-if="detailsMobile && entry === selectedBeneficiary" />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                  <span v-if="newOrder.grouping === 1">&nbsp;&nbsp;{{ detail.cards }}</span>
                  <span v-else>&nbsp;&nbsp;{{ detail.vouchers }}</span>
                </td>
                <td v-if="newOrder.grouping === 1" class="priority-3" valign="middle">
                  {{ detail.vouchers }}
                </td>
                <td class="priority-4" valign="middle">
                  <span>
                    {{ detail.nominal_value }}&nbsp;&nbsp;{{ $t("currency") }}
                  </span>
                </td>
                <td class="priority-5" valign="middle">
                  <span>{{ getAddressDisplayName(detail.address) }}</span>
                </td>
              </tr>
              <tr :key="index + confirmationOrder.detailsPag.length" class="child" :class="index % 2 === 0 && 'gri-cell'"
                v-show="detailsMobile && detail === selectedBeneficiary">
                <td colspan="12">
                  <p v-if="newOrder.grouping === 1" class="priority-show-3">
                    <strong>
                      {{ $t("orderNumberTicketsPlaceholder") }}: &nbsp;&nbsp;
                    </strong>
                    <span>
                      {{ detail.vouchers }}&nbsp;&nbsp;{{ $t("currency") }}
                    </span>
                  </p>
                  <p class="priority-show-4">
                    <strong>
                      {{ $t("beneficiariesTableTicketValue") }}: &nbsp;&nbsp;
                    </strong>
                    <span>
                      {{ detail.nominal_value }}&nbsp;&nbsp;{{ $t("currency") }}
                    </span>
                  </p>
                  <p class="priority-show-5">
                    <strong>
                      {{ $t("beneficiariesTableAddress") }} : &nbsp;&nbsp;
                    </strong>
                    <span>{{ getAddressDisplayName(detail.address) }}</span>
                  </p>
                </td>
              </tr>
            </template>
          </table>

          <table v-else cellpadding="10" cellspacing="0" width="95%" class="hover-table" align="center">
            <tr>
              <th valign="middle" @click="() => (sortBy = 'last_name')">
                <strong v-if="newOrder.type !== 'PAPER'">{{
                  $t("beneficiariesTableName")
                }}</strong>
                <strong v-else>{{
                  $t("beneficiariesTableNameAndSurname")
                }}</strong>
                <img src="@/assets/images/arrow-down-fill.svg" @click="sortBy === 'last_name' && changeReverse()" :style="
                  sortBy === 'last_name' &&
                  reverse &&
                  'transform: rotate(180deg)'
                " />
              </th>
              <th v-if="newOrder.type !== 'PAPER'" valign="middle" @click="() => (sortBy = 'first_name')">
                <strong>{{ $t("beneficiariesTableSurname") }}</strong>&nbsp;
                <img src="@/assets/images/arrow-down-fill.svg" @click="sortBy === 'first_name' && changeReverse()" :style="
                  sortBy === 'first_name' &&
                  reverse &&
                  'transform: rotate(180deg)'
                " />
              </th>
              <th class="priority-0" valign="middle">
                <strong>{{ $t("beneficiariesTableCNP") }}</strong>&nbsp;
              </th>
              <th class="priority-1" v-if="
                (confirmationOrder.type === 'NEW' ||
                confirmationOrder.type === 'REN') && orderProductVirtual
              " valign="middle">
                <strong>{{ $t("beneficiariesTableCardType") }}</strong>&nbsp;
              </th>
              <th class="priority-1" v-if="
                confirmationOrder.type === 'NEW' ||
                confirmationOrder.type === 'EXNT' ||
                confirmationOrder.type === 'REN'
              " valign="middle">
                <strong>{{ $t("beneficiariesTableAddress") }}</strong>&nbsp;
              </th>
              <th class="priority-2" v-if="
                confirmationOrder.type !== 'NEWCR' &&
                confirmationOrder.type !== 'EXNT'
              " valign="middle">
                <strong>{{ $t("beneficiariesTableDepartment1") }}</strong>&nbsp;
              </th>
              <th class="priority-3" v-if="
                confirmationOrder.type !== 'NEWCR' &&
                confirmationOrder.type !== 'EXNT'
              " valign="middle">
                <strong>{{ $t("beneficiariesTableDepartment2") }}</strong>
              </th>
              <th class="priority-4" v-if="
                confirmationOrder.type !== 'NEWCR' &&
                confirmationOrder.type !== 'EXNT'
              " valign="middle">
                <strong>{{ $t("beneficiariesTableMobile") }}</strong>
              </th>
              <th class="priority-4" v-if="
                confirmationOrder.type !== 'NEWCR' &&
                confirmationOrder.type !== 'EXNT'
              " valign="middle">
                <strong>{{ $t("beneficiariesTableEmail") }}</strong>
              </th>
              <th class="priority-2" v-if="
                confirmationOrder.type === 'NEWCR' ||
                confirmationOrder.type === 'EXNT'
              " valign="middle">
                <strong>{{ $t("beneficiariesTableNoOfTickets") }}</strong>&nbsp;
              </th>
              <th class="priority-3" v-if="
                confirmationOrder.type === 'NEWCR' ||
                confirmationOrder.type === 'EXNT'
              " valign="middle">
                <strong>{{ $t("beneficiariesTableTicketValue") }}</strong>
              </th>
              <th class="priority-4" v-if="
                confirmationOrder.type === 'NEWCR' ||
                confirmationOrder.type === 'EXNT'
              " valign="middle">
                <strong>{{ $t("beneficiariesTableTotalValue") }}</strong>&nbsp;
              </th>
            </tr>
            <tr v-if="this.confirmationOrder.detailsPag === null">
              <td colspan="12">
                <div class="loading">
                  <img src="@/assets/images/loading.gif" />
                </div>
              </td>
            </tr>
            <template v-for="(detail, index) in this.confirmationOrder.detailsPag">
              <tr :key="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'">
                <td>
                  <a class="detailsOrderMobile display-inline-block" :class="
                    confirmationOrder.type !== 'NEW'
                      ? 'priority-show-4'
                      : 'priority-show-1'
                  " @click="
  selectBeneficiaryMobile(
    index,
    confirmationOrder.detailsPag
  )
">
                    <font-awesome-icon icon="minus-circle" v-if="detailsMobile && detail === selectedBeneficiary" />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                  <span>{{ detail.last_name }}</span>
                </td>
                <td v-if="newOrder.type !== 'PAPER'" valign="middle">
                  {{ detail.first_name }}
                </td>
                <td class="priority-0" valign="middle">{{ detail.cnp }}</td>
                <td v-if="
                  (confirmationOrder.type === 'NEW' ||
                  confirmationOrder.type === 'REN') && orderProductVirtual
                " class="priority-1" valign="middle">
                  {{ detail.card_virtual ? $t("beneficiariesTableCardTypeVirtual") :  $t("beneficiariesTableCardTypePhysical") }}
                </td>
                <td v-if="
                  confirmationOrder.type === 'NEW' ||
                  confirmationOrder.type === 'EXNT' ||
                  confirmationOrder.type === 'REN'
                " class="priority-1" valign="middle">
                  {{ getAddressDisplayName(detail.address) }}
                </td>
                <td v-if="
                  confirmationOrder.type !== 'NEWCR' &&
                  confirmationOrder.type !== 'EXNT'
                " class="priority-2" valign="middle">
                  {{ detail.department1 }}
                </td>
                <td v-if="
                  confirmationOrder.type !== 'NEWCR' &&
                  confirmationOrder.type !== 'EXNT'
                " class="priority-3" valign="middle">
                  {{ detail.department2 }}
                </td>
                <td v-if="
                  confirmationOrder.type !== 'NEWCR' &&
                  confirmationOrder.type !== 'EXNT'
                " class="priority-4" valign="middle">
                  {{ detail.phone }}
                </td>
                <td v-if="
                  confirmationOrder.type !== 'NEWCR' &&
                  confirmationOrder.type !== 'EXNT'
                " class="priority-5" valign="middle">
                  {{ detail.email }}
                </td>
                <td v-if="
                  confirmationOrder.type === 'NEWCR' ||
                  confirmationOrder.type === 'EXNT'
                " class="priority-2" valign="middle">
                  {{ detail.vouchers }}
                </td>
                <td v-if="
                  confirmationOrder.type === 'NEWCR' ||
                  confirmationOrder.type === 'EXNT'
                " class="priority-3" valign="middle">
                  {{ detail.nominal_value }}
                </td>
                <td v-if="
                  confirmationOrder.type === 'NEWCR' ||
                  confirmationOrder.type === 'EXNT'
                " class="priority-4" valign="middle">
                  {{ detail.total_nominal_value }}
                </td>
              </tr>
              <tr :key="index + confirmationOrder.detailsPag.length" class="child" :class="index % 2 === 0 && 'gri-cell'"
                v-show="detailsMobile && detail === selectedBeneficiary">
                <td colspan="12">
                  <p class="priority-show-0">
                    <strong>{{ $t("beneficiariesTableCNP") }} : &nbsp;&nbsp;</strong>
                    <span>{{ detail.cnp }}</span>
                  </p>
                  <p v-if="(orderType === 'NEW' || orderType === 'REN') && orderProductVirtual" class="priority-show-1">
                    <strong>{{ $t("beneficiariesTableCardType") }} &nbsp;&nbsp;</strong>
                    <span>{{ detail.card_virtual ? $t("beneficiariesTableCardTypeVirtual") :  $t("beneficiariesTableCardTypePhysical")  }}</span>
                  </p>
                  <p v-if="
                    confirmationOrder.type === 'NEW' ||
                    confirmationOrder.type === 'EXNT' ||
                    confirmationOrder.type === 'REN'
                  " class="priority-show-1">
                    <strong>
                      {{ $t("beneficiariesTableAddress") }} : &nbsp;&nbsp;
                    </strong>
                    <span>{{ getAddressDisplayName(detail.address) }}</span>
                  </p>
                  <p v-if="
                    confirmationOrder.type !== 'NEWCR' &&
                    confirmationOrder.type !== 'EXNT'
                  " class="priority-show-2">
                    <strong>
                      {{ $t("beneficiariesTableDepartment1") }} : &nbsp;&nbsp;
                    </strong>
                    <span>{{ detail.department1 }}</span>
                  </p>
                  <p v-if="
                    confirmationOrder.type !== 'NEWCR' &&
                    confirmationOrder.type !== 'EXNT'
                  " class="priority-show-3">
                    <strong>
                      {{ $t("beneficiariesTableDepartment2") }} : &nbsp;&nbsp;
                    </strong>
                    <span>{{ detail.department1 }}</span>
                  </p>
                  <p v-if="
                    confirmationOrder.type !== 'NEWCR' &&
                    confirmationOrder.type !== 'EXNT'
                  " class="priority-show-4">
                    <strong>
                      {{ $t("beneficiariesTableMobile") }} : &nbsp;&nbsp;
                    </strong>
                    <span>{{ detail.phone }}</span>
                  </p>
                  <p v-if="
                    confirmationOrder.type !== 'NEWCR' &&
                    confirmationOrder.type !== 'EXNT'
                  " class="priority-show-5">
                    <strong>
                      {{ $t("beneficiariesTableEmail") }} : &nbsp;&nbsp;
                    </strong>
                    <span>{{ detail.email }}</span>
                  </p>
                  <p v-if="
                    confirmationOrder.type === 'NEWCR' ||
                    confirmationOrder.type === 'EXNT'
                  " class="priority-show-2">
                    <strong>
                      {{ $t("beneficiariesTableNoOfTickets") }} : &nbsp;&nbsp;
                    </strong>
                    <span>{{ detail.vouchers }}</span>
                  </p>
                  <p v-if="
                    confirmationOrder.type === 'NEWCR' ||
                    confirmationOrder.type === 'EXNT'
                  " class="priority-show-3">
                    <strong>
                      {{ $t("beneficiariesTableTicketValue") }} : &nbsp;&nbsp;
                    </strong>
                    <span>{{ detail.nominal_value }}</span>
                  </p>
                  <p v-if="
                    confirmationOrder.type === 'NEWCR' ||
                    confirmationOrder.type === 'EXNT'
                  " class="priority-show-4">
                    <strong>
                      {{ $t("beneficiariesTableTotalValue") }} : &nbsp;&nbsp;
                    </strong>
                    <span>{{ detail.total_nominal_value }}</span>
                  </p>
                </td>
              </tr>
            </template>
          </table>

          <div class="pagination-wrapper border-box" v-if="this.totalDetails > 0">
            <b-pagination v-model="currentPage" :total-rows="totalDetails" :per-page="perPage"
              aria-controls="orders-table" size="sm"></b-pagination>
          </div>
        </div>
      </div>
      <a v-if="this.userRole !== 'FAC'" href="#" class="notification-action-small step4" @click="confirmOrder()">{{
        $t("finalStepButtonLabel") }}</a>
    </div>

    <!-- order step 5 congrats ; proform download -->
    <div v-if="this.orderStep === 5">
      <p v-if="this.newOrder.type === 'REN'" align="center" v-html="$t('orderSuccessMessageREN')"></p>
      <p v-else align="center" v-html="$t('orderSuccessMessage')"></p>

      <p v-if="
        this.confirmationOrder.total_amount_value > 0 &&
        this.confirmationOrder.number === 0
      " align="center">
        <b>{{ $t("proformToMail") }}</b>
      </p>

      <table cellpadding="10" cellspacing="0" class="hover-table delivery" align="center">
        <tr>
          <td width="50%">
            <a v-if="
              this.confirmationOrder.total_amount_value > 0 &&
              this.confirmationOrder.number > 0
            " href="#" class="notification-action status-mint" @click="getOrderProform()">{{
  $t("proformaButtonLabel") }}</a>
          </td>
          <td v-if="this.newOrder.type === 'REN'" width="50%">
            <a href="/customer/beneficiaries?status_card=RN" class="notification-action status-orange">{{
              $t("beneficiariesName") }}</a>
          </td>
          <td v-else width="50%">
            <a
             @click="pushRoute('customer_orders')"
             class="notification-action status-orange cursor-pointer">{{ $t("ordersButtonLabel") }}</a>
          </td>
        </tr>
      </table>
    </div>

    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_info', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <b>
          <p v-if="this.error" v-html="
            $t('errorActionBeneficiary_' + this.error.code, {
              index: this.indexBeneficiaryNoVouchers
            })
          "></p>
        </b>
        <div v-if="this.actionOrderErrors" class="error-scroll">
          <export-excel :data="this.actionOrderErrors" type="csv" :fields="this.excelFields" class="download-wrapper">
            <a class="action-button margin-bottom-10">Descarca toate</a>
          </export-excel>
          <br />
          <p v-for="(error, index) in this.actionOrderErrors" :key="index" class="trhover"
            :class="index % 2 === 0 && 'gri-cell'">
            {{ getErrorDetails(error) }}
          </p>
        </div>
        <p v-if="
          this.newOrder.successLink &&
          !this.error &&
          (!this.actionOrderErrors || this.actionOrderErrors.length === 0)
        " v-html="$t('actionUploadFileSuccess')"></p>
        <br />
        <p v-if="
          this.newOrder.successLink &&
          !this.error &&
          (!this.actionOrderErrors || this.actionOrderErrors.length === 0) && 
          (this.confirmationOrder.type === 'NEW' || this.confirmationOrder.type === 'REN' ) && 
          !this.confirmationOrder.virtual" v-html="$t('oldFileInfoText')"></p>
      </div>
    </div>
    <div id="modal_popup_mixorder" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_mixorder', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p>{{ $t("orderIsMixOrder") }}</p>
      </div>
    </div>
    <div id="modal_popup_validFrom" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_validFrom', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p>{{ $t("orderValidFromPopupText") }}</p>
      </div>
    </div>
    <div id="modal_popup_products" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_products', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p>{{ $t("orderHasNotProduct") }}</p>
      </div>
    </div>
    <div id="modal_popup_loading" class="modal-popup">
      <div class="modal-popup-content">
        <img src="@/assets/images/loading.gif" />
        <p v-html="$t('loadingPopupMessage')">
        </p>
      </div>
    </div>
    <!-- <div v-if="productLogo" id="modal_popup_openLogo" class="modal-popup">
      <div class="modal-popup-content">
        <a
          @click="showModalPopup('modal_popup_openLogo', false)"
          class="close-button"
        >
          <img src="@/assets/images/close-button.svg" />
        </a>
        <img
          :src="'http://app.uponline.ro/img/paper/logo/' + productLogo + '.bmp'"
        />
      </div>
    </div> -->
    <div id="modal_popup_removeVouchers" class="modal-popup">
      <div class="modal-popup-content text-left">
        <a @click="showModalPopup('modal_popup_removeVouchers', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="newOrder.product && newOrder.product.card === 0">
          <span v-html="$t('removeVouchersText1')"></span>
          <span>
            <a class="button-orange" @click="sendMailForAdditionlContract()">{{
              $t("removeVouchersButtonText")
            }}</a>
          </span>
          <span v-html="$t('removeVouchersText2')"></span>
        </div>
        <div v-else>
          <span v-html="$t('removeVouchersIfHasCardCustomerText')"></span>
        </div>
      </div>
    </div>
    <div id="modal_popup_virtual" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_virtual', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-html="this.allVirtual ? $t('virtualBeneficiariesText') : $t('physicalBeneficiariesText')"></p>
        <a class="button-orange" @click="readVirtualBeneficiaries()">{{
          $t("nextStepButtonLabel")
        }}</a>
        <a class="button-orange" @click="showModalPopup('modal_popup_virtual', false)">{{
          $t("backButtonLabel")
        }}</a>
      </div>
    </div>
    <div id="modal_popup_new_file" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_new_file', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-html="$t('newFileInfoText')"></p>
        <a class="button-orange" @click="showModalPopup('modal_popup_new_file', false)">{{
          $t("nextStepButtonLabel")
        }}</a>
      </div>
    </div>

  </div>
</template>

<script>
import destinations from "@/assets/json/destinations.js";
import httpServiceCompany from "@/http/http-user.js";
import httpServiceCustomer from "@/http/http-customer.js";
import httpService from "@/http/http-common.js";
import utils from "@/plugins/utils.js";
import validator from "@/plugins/fieldValidation.js";
import Cookies from "js-cookie";
import $ from "jquery";
import { saveAs } from "file-saver";
import { BContainer, BRow, BCol, BFormFile, BPagination } from "bootstrap-vue";
import OrdersTableDesktop from "./OrdersTableDesktop.vue";
import DateRange from "@/components/DateRange.vue";
import moment from "moment";

export default {
  props: {
    customer: Object,
    userRole: String,
    user: Object,
    msg: String
  },
  components: {
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol,
    "b-form-file": BFormFile,
    "b-pagination": BPagination,
    OrdersTableDesktop,
    DateRange
  },
  data() {
    return {
      fileReceived: null,
      deliveryAddresses: null,
      customerBeneficiaries: null,
      customerComments: null,
      keyword: "",
      lastKeyword: "",
      filterIndex: 0,
      newOrder: {
        type: "",
        destination: 0,
        product: null,
        deliveryAddress: null,
        delivery_pickup: false,
        content: null,
        customer_id: this.customer.company_id,
        successLink: null,
        validFrom: null,
        ignoreMaxTickets: null,
        ignoreCnpValid: null,
        renewForCurrentMonth: null,
        fileBeneficiaries: false,
        fileRenewAddress: false,
        personalized: true,
        grouping: 1,
        fileUploaded: false,
        excelColumns: [
          { index: 1, code: 1 },
          { index: 2, code: 4 },
          { index: 3, code: 6 },
          { index: 4, code: 7 },
          { index: 5, code: 0 },
          { index: 6, code: 0 },
          { index: 7, code: 0 },
          { index: 8, code: 0 },
          { index: 9, code: 0 },
          { index: 10, code: 0 },
          { index: 11, code: 0 }
        ],
        previousOrder: null
      },
      productCustomer: null,
      confirmationOrder: null,
      orderStep: 1, // 1 - CHOOSE ACTION,
      orderType: "", // NEW, NEWCR, PAPER, DUPLICATE
      token: null,
      sortBy: "first_name",
      reverse: false,
      error: null,
      actionOrderErrors: null,
      excelFields: {
        linie: "from_source_id",

        Eroare: {
          callback: (error) => {
            return this.getErrorDetails(error);
          }
        }
      },
      noOfMonths: 12,
      detailsMobile: false,
      selectedBeneficiary: null,
      defaultVouchers: 0,
      defaultTicketValue: 20.17,
      availableExcelColumns: null,
      numberOfCarnets: "",
      numberOfTickets: "",
      ticketValue: 0,
      previousOrders: null,
      newBeneficiary: false,
      b_first_name: "",
      b_last_name: "",
      b_cnp: null,
      b_deliveryAddresses: 1,
      b_mobileNumber: null,
      b_email: "",
      b_vouchers: null,
      b_nominal_value: 20.17,
      b_booklets: null,
      b_virtual: null,
      openAddBeneficiary: false,
      period: [moment()],
      periodTo: [moment()],
      order_type: true,
      filters: {
        product: "dejun",
        period: [moment().subtract(3, "month"), moment()]
      },
      minDateValidFrom: moment().toDate(),
      perPage: 10,
      currentPage: 1,
      sortField: "order_date",
      sortDirection: "desc",
      packageProduct: {
        delivery48: "false",
        end_period: "31",
        group_carnet: "true",
        group_pachet: "true",
        group_planse: "true",
        id: "4",
        name: "COMPLET",
        start_period: "1"
      },
      todayOrder: 0,
      maxVNPopup: false,
      isEditing: false,
      oldValue: "",
      oldSelectionStart: null,
      oldSelectionEnd: null,
      selectionStart: null,
      selectionEnd: null,
      indexBeneficiaryNoVouchers: null,
      totalDetails: 0,
      successSentEmailToCommercial: false,
      sentEmailToCommercialError: null,
      config: JSON.parse(sessionStorage.getItem("client_jsonFile")),
    };
  },
  methods: {
    addBeneficiary() {
      if (this.newOrder.content !== null) {
        this.customerBeneficiaries = null;
      }
      let productCustomer = utils.getProductById(
        this.orderType  !== "PAPER" ? this.newOrder.product.card : this.newOrder.product.paper,
        this.customer.products
      );
      this.error = null;
      if (this.newOrder.type !== "PAPER") {
        if (!this.customerBeneficiaries && this.newOrder.type !== "NEWCR") {
          this.customerBeneficiaries = [];
        }
        if (this.newOrder.type !== "NEWCR") {
          if (this.b_first_name.length === 0 || this.b_last_name.length === 0) {
            this.error = { code: "NO_DATA_NAME" };
            this.showModalPopup("modal_popup_info", true);
          } else if (!this.b_cnp || this.b_cnp.length === 0 || this.b_cnp.length !== 13) {
            this.error = { code: "CNP_INVALID" };
            this.showModalPopup("modal_popup_info", true);
          } else if (this.b_virtual == null ) {
            this.error = { code: "VIRTUAL_INVALID" };
            this.showModalPopup("modal_popup_info", true);
          } else if (
            productCustomer.pinsMode === "IVR" &&
            (this.b_mobileNumber === null ||
            this.b_mobileNumber.length === 0 ||
            this.b_mobileNumber.length !== 10)
          ) {
            this.error = { code: "MOBILE_INVALID" };
            this.showModalPopup("modal_popup_info", true);
          } else if (
            (this.b_virtual == true || this.b_virtual == "true" ) && (this.b_email == null ||
            this.b_email.trim().length == 0)
          ) {
            this.error = { code: "EMAIL_INVALID" };
            this.showModalPopup("modal_popup_info", true);
          } else if (
            this.b_email !== null &&
            this.b_email.length > 0 &&
            !validator.validateEmail(this.b_email)
          ) {
            this.error = { code: "EMAIL_INVALID" };
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.customerBeneficiaries.unshift({
              first_name: this.b_first_name.toUpperCase(),
              last_name: this.b_last_name.toUpperCase(),
              cnp: this.b_cnp,
              vouchers: this.b_vouchers,
              nominal_value: this.b_nominal_value,
              address: this.b_deliveryAddresses,
              phone: this.b_mobileNumber,
              email: this.b_email,
              card_virtual: this.b_virtual,
              newBeneficiary: true
            });
            this.resetBeneficiary();
          }
        }
      } else {
        if (!this.customerBeneficiaries) {
          this.customerBeneficiaries = [];
        }
        if (this.newOrder.personalized) {
          if (this.b_last_name.length === 0) {
            this.error = { code: "NO_DATA_NAME" };
            this.showModalPopup("modal_popup_info", true);
          } else if (
            (this.b_cnp && this.b_cnp.length !== 13) ||
            this.b_cnp === null
          ) {
            this.error = { code: "CNP_INVALID" };
            this.showModalPopup("modal_popup_info", true);
          } else if (this.b_vouchers === null) {
            this.error = { code: "NO_DATA_VOUCHERS" };
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.customerBeneficiaries.push({
              first_name: this.b_first_name.toUpperCase(),
              last_name: this.b_last_name.toUpperCase(),
              cnp: this.b_cnp,
              vouchers: this.b_vouchers,
              nominal_value: this.b_nominal_value,
              address: this.b_deliveryAddresses,
              newBeneficiary: true
            });
            this.resetBeneficiary();
          }
        } else {
          if (this.newOrder.grouping === 1 && this.b_vouchers > 45) {
            this.error = { code: "MORE_VOUCHERS_CARNETE" };
            this.showModalPopup("modal_popup_info", true);
          } else if (this.b_vouchers === null) {
            this.error = { code: "NO_DATA_VOUCHERS" };
            this.showModalPopup("modal_popup_info", true);
          } else if (this.newOrder.grouping === 1 && this.b_booklets === null) {
            this.error = { code: "NO_DATA_BOOKLETS" };
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.customerBeneficiaries.push({
              first_name: this.b_first_name.toUpperCase(),
              last_name: this.b_last_name.toUpperCase(),
              cnp: this.b_cnp,
              cards: this.b_booklets,
              vouchers: this.b_vouchers,
              nominal_value: this.b_nominal_value,
              address: this.b_deliveryAddresses,
              newBeneficiary: true
            });
            this.resetBeneficiary();
          }
        }
      }
    },
    resetBeneficiary() {
      this.b_first_name = "";
      this.b_last_name = "";
      this.b_cnp = null;
      this.b_mobileNumber = null;
      this.b_email = "";
      this.b_vouchers = null;
      this.b_booklets = null;
      this.b_deliveryAddresses = 1;
      this.b_nominal_value = this.defaultTicketValue;
      if (this.newOrder.product.card === 15) {
          this.b_virtual = false;
      } else {
        this.b_virtual = null
      }
      this.error = null;
    },
    deleteBeneficiary(index) {
      this.customerBeneficiaries.splice(index, 1);
    },
    updateCustomerBeneficiary(index, prop) {
      if (prop === "name") {
        if (/^[a-zA-Z]+$/.test(event.target.innerText)) {
          this.customerBeneficiaries[index].last_name = event.target.innerText;
        } else {
          event.target.innerText = this.customerBeneficiaries[index].last_name;
        }
      } else if (prop === "cnp") {
        if (/^\d+$/.test(event.target.innerText)) {
          this.customerBeneficiaries[index].cnp = event.target.innerText;
        } else {
          event.target.innerText = this.customerBeneficiaries[index].cnp;
        }
      } else if (prop === "vouchers") {
        if (/^\d+$/.test(event.target.innerText)) {
          this.customerBeneficiaries[index].vouchers = parseInt(
            event.target.innerText
          );
        } else {
          event.target.innerText = this.customerBeneficiaries[index].vouchers;
        }
      } else {
        if (/^\d+$/.test(event.target.innerText)) {
          this.customerBeneficiaries[index].nominal_value = parseInt(
            event.target.innerText
          );
        } else {
          event.target.innerText =
            this.customerBeneficiaries[index].nominal_value + " lei";
        }
      }
    },
    updateNumberOfCarnets(event) {
      this.numberOfCarnets = event.target.value;
    },
    updateNumberOfTickets(event) {
      this.numberOfTickets = event.target.value;
    },
    updateTicketValue(event) {
      this.ticketValue = event.target.value;
    },
    selectBeneficiaryMobile(index, list) {
      this.selectedBeneficiary = list[index];
      this.detailsMobile = !this.detailsMobile;
    },
    openAddBeneficiaryMobile() {
      this.openAddBeneficiary = !this.openAddBeneficiary;
    },
    selectOrderType(type) {
      
      // commment pt Jenkins
      this.newOrder.type = type;
      this.orderType = type;
      this.orderStep = 2;

      this.deliveryAddresses = null;
      this.customerBeneficiaries = null;
      this.customerComments = null;
      this.newOrder.product = null;
      this.productCustomer = null;
      this.newOrder.deliveryAddress = null;
      this.newOrder.content = null;
      this.newOrder.successLink = null;
      this.newOrder.orderMonth = 0;
      this.newOrder.validFrom = null;
      this.newOrder.ignoreMaxTickets = false;
      this.newOrder.ignoreCnpValid = false;
      this.newOrder.renewForCurrentMonth = false;

      this.fileName = "";
      this.confirmationOrder = null;
      this.error = null;
      this.actionOrderErrors = null;

      if (this.orderType === "DUPLICATE") {
        this.selectProduct(this.customer.products[0]);
      }
      if (this.orderType === "REN") {
        if (this.userRole === "FAC") {
          this.order_type = true;
        } else {
          this.order_type = false;
        }
      }
    },
    selectProductDuplicate(event) {
      this.selectProduct(this.customer.products[event.target.value]);
    },
    getProductNameMethod(productId) {
      return utils.getCompanyProductName(productId, this.customer.products);
    },
    selectProduct(product) {
      this.productCustomer = utils.getProductById(
        product.card === 0 ? parseInt(product.paper) : parseInt(product.card),
        this.customer.products
      );
      // if (!this.verifyMonthOrderPeriod(this.productCustomer)) {
      //   this.error = {
      //     code: "PACKAGE"
      //   };
      //   return;
      // }

      this.newOrder.product = product;
      if ((this.newOrder.type === "NEW" || this.newOrder.type === "REN") && this.userRole !== 'FAC' && this.newOrder.product.card != 15){
        this.showModalPopup("modal_popup_new_file", true);
      }
      this.b_virtual = null;

      this.convertVariablesOfProduct(product);

      this.orderStep = 3;
      this.newOrder.personalized = product.perso;
      this.defaultTicketValue = parseFloat(product.maxTicketValue);
      this.b_nominal_value = this.defaultTicketValue;

      this.deliveryAddresses = null;
      this.customerBeneficiaries = null;
      this.customerComments = null;
      this.newOrder.deliveryAddress = null;
      this.newOrder.content = null;
      this.newOrder.successLink = null;
      this.newOrder.orderMonth = 0;
      this.newOrder.validFrom = null;
      this.newOrder.ignoreMaxTickets = false;
      this.newOrder.ignoreCnpValid = false;
      this.newOrder.renewForCurrentMonth = false;

      this.newOrder.destination = "0";

      this.fileName = "";
      this.confirmationOrder = null;
      this.error = null;
      this.actionOrderErrors = null;
      if (this.newOrder.type === "PAPER") {
        this.newOrder.text1 = this.productCustomer.message1;
        this.newOrder.text2 = this.productCustomer.message2;
        if (this.newOrder.product.order === 2) {
          this.newOrder.destination = "6";
        }
        if (this.newOrder.product.paper === 6) {
          this.packageProduct.group_carnet = "false";
          this.packageProduct.group_pachet = "true";
          this.packageProduct.group_planse = "false";
          this.newOrder.grouping = 2;
          this.order_type = true;
        }
        if (this.productLogo) {
          this.newOrder.logo = true;
        }
        this.getDeliveryAddresesForSelectedProduct();
        this.hasAnotherOrderForProduct();
      } else if (this.newOrder.type === "NEW") {
        if (this.newOrder.product.card === 13) {
          this.newOrder.destination = "6";
        }
        if (this.newOrder.product.card === 15) {
          this.b_virtual = false;
        }
        this.getDeliveryAddresesForSelectedProduct();
        this.hasAnotherOrderForProduct();
      } else if (this.newOrder.type === "NEWCR") {
        if (this.newOrder.product.card === 13) {
          this.newOrder.destination = "6";
        }
        this.getDeliveryAddresesForSelectedProduct();
        
          this.loadCustomerBeneficiaries();
        this.hasAnotherOrderForProduct();
      } else if (this.newOrder.type === "DUPLICATE") {
        this.orderStep = 2;
        if (this.newOrder.product.active) {
          this.getPreviousOrdersForProduct();
        } else {
          this.previousOrders = [];
        }
      }
      if (this.userRole === 'FAC') {
        this.loadCustomerComments();
      }
      this.sendClickToGA(
        "Comanda noua - Selectare produs",
        "Click",
        product.name
      );
    },
    getDeliveryAddresesForSelectedProduct() {
      var formData = "?brand=" + this.newOrder.product.brand;
      formData += "&type=customer";
      if (this.newOrder.type === "PAPER") {
        formData += "&environment=paper";
      } else {
        formData += "&environment=card";
      }
      formData += "&per_page=" + 10000 + "&page=" + 1;
      httpServiceCompany.userService
        .getAddressPoints(this.customer.company_id, formData, this.token)
        .then(function (response) {
          return response;
        })
        .catch(() => {
          this.showModalPopup("modal_popup_loading", false);
        })
        .then((response) => {
          if (response.status == 200) {
            this.deliveryAddresses = response.data;
            if (this.deliveryAddresses.length === 1) {
              this.newOrder.deliveryAddress = this.deliveryAddresses[0].codcom;
            }
          } else if (response.status == 400 || response.status == 403) {
            this.deliveryAddresses = null;
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    loadCustomerComments() {

      this.customerComments = null;
      var formData = "?product_id=" + this.newOrder.product.card;
      this.showModalPopup("modal_popup_loading", true);

      httpServiceCustomer.customerService
        .getCustomerComments(this.customer.company_id, formData, this.token)
        .then(function (response) {
          return response;
        })
        .catch(() => {
          this.showModalPopup("modal_popup_loading", false);
        })
        .then((response) => {
          if (response.status == 200) {
            this.customerComments = response.data;
          } else if (response.status == 400 || response.status == 403) {
            this.customerComments = null;
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    loadCustomerBeneficiaries() {
      if (
        this.customer.company_id === 88866 ||
        this.customer.company_id === 13537 ||
        this.userRole === 'FAC'
      ) {
        this.customerBeneficiaries = null;
        return;
      }
      this.customerBeneficiaries = null;
      var formData = "?product=" + this.newOrder.product.card;
      formData += "&per_page=" + 10000 + "&page=" + 1;
      formData += this.newOrder.type === "NEW" ? "&new_card=Y" : "&top_up=Y";
      this.showModalPopup("modal_popup_loading", true);

      httpServiceCustomer.customerService
        .searchOrderCards(formData, this.customer.company_id, this.token)
        .then(function (response) {
          return response;
        })
        .catch(() => {
          this.showModalPopup("modal_popup_loading", false);
        })
        .then((response) => {
          if (response.status == 200) {
            this.customerBeneficiaries = response.data;
            if (this.newOrder.type === "NEWCR") {
              this.setDefaultTicketsValueForNEWCR();
              this.setDefaultNominalValueForNEWCR();
            } else {
              this.setBeneficiaryStatusValueForNEW();
            }
          } else if (response.status == 400 || response.status == 403) {
            this.customerBeneficiaries = null;
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    searchCustomerBeneficiaries() {
      if (this.lastKeyword == this.keyword) {
        this.filterIndex++;
      } else {
        this.lastKeyword = this.keyword;
        this.filterIndex = 0;
      }
      let items = this.customerBeneficiaries.filter(
        (item) =>
          item.last_name.indexOf(this.keyword.toUpperCase()) > -1 ||
          item.first_name.indexOf(this.keyword.toUpperCase()) > -1 ||
          item.cnp.toString().indexOf(this.keyword.toUpperCase()) > -1
      );
      if (this.filterIndex >= items.length) {
        this.filterIndex = 0;
      }

      $("#vouchers_" + items[this.filterIndex].cnp).focus();
    },
    sortPreviousOrdersBy(sortField, sortDirection) {
      this.sortField = sortField;
      this.sortDirection = sortDirection;
      this.getPreviousOrdersForProduct();
    },
    sortOrdersBy(sortField, sortDirection) {
      this.sortField = sortField;
      this.sortDirection = sortDirection;
      this.getPreviousOrdersForProduct();
    },
    getPreviousOrdersForProduct() {
      this.previousOrders = null;
      var ordersData =
        "?type=" +
        "customer" +
        // "&order_type=" +
        // "NEWCR,PAPER" +
        "&brand=" +
        this.newOrder.product.brand +
        "&start_date=" +
        moment(this.filters.period[0]).format("YYYYMMDD") +
        "&end_date=" +
        moment(this.filters.period[1]).format("YYYYMMDD");
      ordersData += "&status=L,N,P,S,PR,PRP,PV,R,V";

      ordersData +=
        "&per_page=" +
        this.perPage +
        "&page=" +
        this.currentPage +
        "&sort=" +
        this.sortDirection;
      if (this.sortDirection === "desc") {
        ordersData += "&desc=" + this.sortField;
      } else {
        ordersData += "&asc=" + this.sortField;
      }
      httpServiceCustomer.customerService
        .searchOrders(
          ordersData,
          this.customer.company_id,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .catch(() => {
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        })
        .then((response) => {
          if (response.status == 200) {
            this.previousOrders = response.data;
          } else if (response.status == 400 || response.status == 403) {
            this.orderError = {
              code: response.data,
              description: "Error fetching invoices"
            };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.orderError = { description: response.description };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        });
    },
    setDefaultTicketsValueForNEWCR() {
      if (this.customerBeneficiaries !== null) {
        for (let index in this.customerBeneficiaries) {
          this.customerBeneficiaries[index].vouchers = this.defaultVouchers;
        }
      }
    },
    setBeneficiaryStatusValueForNEW() {
      if (this.customerBeneficiaries !== null) {
        for (let index in this.customerBeneficiaries) {
          this.customerBeneficiaries[index].newBeneficiary = true;
        }
      }
    },
    setDefaultNominalValueForNEWCR() {
      if (this.customerBeneficiaries !== null) {
        for (let index in this.customerBeneficiaries) {
          this.customerBeneficiaries[index].nominal_value =
            this.defaultTicketValue;
          this.customerBeneficiaries[index].total_nominal_value =
            this.defaultVouchers * this.defaultTicketValue;
        }
      }
    },
    downloadModel() {
      if (this.orderType === "DUPLICATE") {
        httpServiceCustomer.customerService
          .getOrderModelFile(
            this.customer.company_id,
            this.newOrder.previousOrder,
            this.newOrder.type,
            this.token
          )
          .then(function (response) {
            return response;
          })
          .then((response) => {
            if (response.status == 200) {
              var blob = new Blob(
                [utils.base64ToArrayBuffer(response.data.content)],
                {
                  type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel"
                }
              );
              saveAs(blob, response.data.name);
            }
            this.showModalPopup("modal_popup_loading", false);
          })
          .catch(() => {
            this.showModalPopup("modal_popup_loading", false);
          });
      } else {
        var formData = {
          product: 0,
          customer_id: this.customer.company_id,
          lang: localStorage.language,
          type:
            this.newOrder.type === "PAPER"
              ? "EXNT" + this.newOrder.grouping
              : this.newOrder.fileBeneficiaries
                ? "NEW_BENEF" 
                : this.newOrder.fileRenewAddress
                ? "REN_ADR" 
                : this.newOrder.type
        };
        if (this.newOrder.type === "PAPER") {
          formData.product = this.newOrder.product.paper;
          formData.mappings = this.mappings;
        } else {
          formData.product = this.newOrder.product.card;
        }

        httpServiceCustomer.customerService
          .getOrderTemplateModelFile(
            this.customer.company_id,
            formData,
            this.token
          )
          .then(function (response) {
            return response;
          })
          .catch(() => {
            this.showModalPopup("modal_popup_loading", false);
          })
          .then((response) => {
            if (response.status == 200) {
              var blob = new Blob(
                [utils.base64ToArrayBuffer(response.data.content)],
                {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel",
                }
              );
              saveAs(blob, response.data.name);
            } else if (response.status == 400 || response.status == 403) {
              this.deliveryAddresses = null;
            }
            this.showModalPopup("modal_popup_loading", false);
          });
      }
    },
    changeReverse() {
      this.reverse = !this.reverse;
    },
    uploadFileToServer() {
      this.showModalPopup("modal_popup_loading", true);

      this.actionOrderErrors = null;
      this.error = null;
      var orderData = {
        product: 0,
        content: this.newOrder.content,
        customer_id: this.customer.company_id,
        type: this.newOrder.type === "PAPER" ? "EXNT" : this.newOrder.type
      };

      if (this.newOrder.type === "PAPER") {
        orderData.product = this.newOrder.product.paper;
        orderData.packing = this.newOrder.grouping;
        orderData.message1 = this.newOrder.text1
          ? this.newOrder.text1.normalize("NFD").replace(/\p{Diacritic}/gu, "")
          : this.newOrder.text1;
        orderData.message2 = this.newOrder.text2
          ? this.newOrder.text2.normalize("NFD").replace(/\p{Diacritic}/gu, "")
          : this.newOrder.text2;
        orderData.mappings = this.mappings;
        orderData.delivery_pickup = this.newOrder.delivery_pickup;
        orderData.saturday_delivery = this.newOrder.saturday_delivery;
        orderData.personalized = this.newOrder.personalized;
        orderData.logo = this.newOrder.logo;
        if (this.newOrder.product.paper === 2) {
          orderData.market = this.newOrder.destination;
        }
      } else {
        orderData.product = this.newOrder.product.card;
        orderData.charge_date = this.period[0];
        orderData.charge_month = this.newOrder.orderMonth;
        orderData.ignore_max_tickets = this.newOrder.ignoreMaxTickets;
        orderData.ignore_cnp_valid = this.newOrder.ignoreCnpValid;
        if (this.newOrder.product.card === 13) {
          orderData.market = this.newOrder.destination;
        }
        if (this.newOrder.product.card === 15) {
          orderData.valid_to_date = this.periodTo[0];
        }
        if (this.newOrder.type === "REN" && this.userRole === "FAC" ) {
          let renewMonth = (this.newOrder.renewForCurrentMonth ? moment() : moment().add(1, 'M'))
          let renewYear = (renewMonth.year() + "").substring(2);
          renewMonth = renewMonth.month() + 1
          orderData.renew_month = (renewMonth > 9 ? "" : "0")  + renewMonth + "" + renewYear;
        }
      }
      httpServiceCustomer.customerService
        .uploadOrderFile(
          this.customer.company_id,
          orderData,
          this.token,
          this.userRole === "FAC" || this.userRole === "HLP" ? this.user.username : null
        )
        .then(function (response) {
          return response;
        })
        .catch((error) => {
          return error.response;
        })
        .then((response) => {
          if (response.status === 200) {
            let nextLink = response.headers["link"];
            nextLink = nextLink.split(";")[0];
            nextLink = nextLink.replace(">", "");
            nextLink = nextLink.replace("<", "");
            this.confirmationOrder = response.data;
            this.getConfirmationOrderDetails();
            
            this.newOrder.successLink = nextLink;
            this.orderStep = 4;
            this.actionOrderErrors = null;
            this.showModalPopup("modal_popup_loading", false);
            if (this.confirmationOrder.details && this.confirmationOrder.details.length > 0) {
              this.showModalPopup("modal_popup_mixorder", true);
            } else {
              this.showModalPopup("modal_popup_info", true);
            }
            this.error = null;
          } else if (response.status === 406) {
            console.log("not acceptable");
            this.showModalPopup("modal_popup_loading", false);
            this.error = {code: "NOTACCEPTABLE"};
            this.showModalPopup("modal_popup_info", true);
          } else if (response.status === 400) {
            this.showModalPopup("modal_popup_loading", false);
            this.backOneStep();
          } else {
            this.getResponseErrors(response);
          }
        });
    },
    getConfirmationOrderDetails() {
      this.confirmationOrder.detailsPag = null;
      // this.showModalPopup("modal_popup_loading", true);
      let searchParams =
        "?per_page=" + this.perPage + "&page=" + this.currentPage;
      // if (this.search.length > 0) {
      //   searchParams += "&keyword=" + this.search;
      // }
      httpServiceCustomer.customerService
        .getOrderDetails(
          this.confirmationOrder.id,
          this.customer.company_id,
          Cookies.get("token"),
          this.confirmationOrder.order_type,
          searchParams
        )
        .then(function (response) {
          return response;
        })
        .then((response) => {
          if (response.status == 200) {
            this.confirmationOrder.detailsPag = response.data;
            this.totalDetails = response.headers["x-up-count"];
            this.$forceUpdate();
          } else if (response.status == 400 || response.status == 403) {
            this.error = {
              code: "DETAILS"
            };
          } else {
            this.error = {
              code: "DETAILS"
            };
          }
        })
        .catch(() => {
          this.error = {
            code: "DETAILS"
          };
        });
    },
    changeVoucherValueForEntry(event, entry) {
      entry.vouchers = event.value;
      entry.total_nominal_value = entry.vouchers * entry.nominal_value;
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    changeValidDate(value) {
      this.period = value;
      if (
        this.customer.future_loading &&
        this.user.role !== "FAC" &&
        this.period[0] > moment()
      ) {
        this.showModalPopup("modal_popup_validFrom", true);
      }
    },
    changeValidToDate(value) {
      this.periodTo = value;
      // if (
      //   this.customer.future_loading &&
      //   this.user.role !== "FAC" &&
      //   this.periodTo[0] > moment()
      // ) {
      //   this.showModalPopup("modal_popup_validFrom", true);
      // }
    },
    selectExcelColumn(index, value) {
      this.newOrder.excelColumns[index].code = Number(value);
      this.verifyExcelColumns();
    },
    backOneStep() {
      this.orderStep--;
      switch (this.orderStep) {
        case 1:
          this.orderType = "";
          this.newOrder.type = "";
          this.currentPage = 1;
          break;
        case 2:
          if (this.orderType !== "DUPLICATE") {
            this.newOrder.product = null;
            this.productCustomer = null;
          }
          this.newOrder.deliveryAddress = null;
          this.currentPage = 1;
          delete this.newOrder.logo;
          break;
        case 3:
          if (this.newOrder.content !== null) {
            this.customerBeneficiaries = null;
          }
          this.newOrder.content = null;
          this.newOrder.successLink = null;
          this.newOrder.orderMonth = 0;
          this.newOrder.destination = "0";
          this.newOrder.validFrom = null;
          this.newOrder.ignoreMaxTickets = false;
          this.newOrder.ignoreCnpValid = false;
          this.newOrder.renewForCurrentMonth = false;

          break;
      }
    },
    getResponseErrors(res) {
      if (res.status === 500) {
        this.error = { code: 500 };

        this.showModalPopup("modal_popup_loading", false);
        this.showModalPopup("modal_popup_info", true);
        return;
      }
      let errorsLink = res.headers["link"];
      if (!errorsLink) {
        this.showModalPopup("modal_popup_loading", false);
        this.showModalPopup("modal_popup_info", true);
        this.error = { code: 500 };
        return;
      }
      errorsLink = errorsLink.split(";")[0];
      errorsLink = errorsLink.replace(">", "");
      errorsLink = errorsLink.replace("<", "");
      if (errorsLink.includes("exceptions_type")) {
        httpServiceCustomer.customerService
          .getOrderErros(errorsLink, this.token)
          .then((response) => {
            if (response.status === 200) {
              this.actionOrderErrors = response.data.errors;
              this.actionOrderErrors.sort(
                (a, b) =>
                  parseFloat(a.from_source_id) - parseFloat(b.from_source_id)
              );
              this.error = { code: "ERROR" };
            } else {
              this.error = { code: "500" };
            }
          })
          .catch((error) => {
            if (error) {
              this.error = { code: error.response.data.code };

              this.viewDetails(false);
            } else {
              this.error = { code: "500" };
            }
          });

        this.showModalPopup("modal_popup_loading", false);
        this.showModalPopup("modal_popup_info", true);
      }
    },
    getErrorDetails(error) {
      let desc = error.description.split("#");
      return this.$t(error.error_code, {
        name: error.from_source_id,
        field: desc[0],
        info: desc[1]
      });
    },
    uploadFile() {
      // this.newOrder.content =
      // utils.uploadFile(
      //   this.fileReceived
      //   // this.newOrder.content
      // );
      // /*\

      var reader = new FileReader();
      reader.onload = (e) => {
        if (!e) {
          this.newOrder.content = btoa(reader.content);
        } else {
          this.newOrder.content = btoa(e.target.result);
        }
      };
      //For Browsers other than IE.
      if (reader.readAsBinaryString) {
        reader.readAsBinaryString(this.fileReceived);
      } else {
        alert("This browser does not support HTML5.");
      }

      if (!this.fileReceived) {
        return;
      }
      // */
    },
    displayDate(date) { return moment(new Date(date), "YYYY-MM-DDThh:mm:ss").format("DD/MM/YYYY"); },
    selectOrder(order) {
      this.newOrder.previousOrder = order.id;
      this.newOrder.type = order.type;

      this.showModalPopup("modal_popup_loading", true);
      this.getCustomerOrderHeader();
      this.getOrderDetails();
      this.getDeliveryAddresesForSelectedProduct();
    },
    getCustomerOrderHeader() {
      httpServiceCustomer.customerService
        .getOrderHeader(
          this.newOrder.previousOrder,
          this.customer.company_id,
          Cookies.get("token"),
          this.newOrder.type
        )
        .then(function (response) {
          return response;
        })
        .catch(() => {
          this.showModalPopup("modal_popup_loading", false);
        })
        .then((response) => {
          if (response.status == 200) {
            this.newOrder.product = utils.getProductById(
              response.data.product,
              this.config.products
            );
            if (this.newOrder.type === "PAPER") {
              this.newOrder.personalized = response.data.personalized;
              this.newOrder.destination = response.data.market;
              if (
                this.newOrder.destination !== 6 &&
                this.newOrder.product.paper === 2
              ) {
                this.newOrder.product.order = 3;
              }
              this.newOrder.saturday_delivery = response.data.saturday_delivery;
              this.newOrder.delivery_pickup = response.data.delivery_pickup;
              this.newOrder.grouping = response.data.packing
                ? response.data.packing
                : 1;

              this.newOrder.text1 = response.data.message1;
              this.newOrder.text2 = response.data.message2;
              this.newOrder.logo = response.data.logo;
            } else {
              this.newOrder.personalized = true;
            }
          } else if (response.status == 400 || response.status == 403) {
            this.orderError = {
              code: response.data,
              description: "Error fetching invoices"
            };
          } else {
            this.orderError = { description: response.description };
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },
    getOrderDetails() {
      let searchParams = "?per_page=10000&page=1";
      httpServiceCustomer.customerService
        .getOrderDetails(
          this.newOrder.previousOrder,
          this.customer.company_id,
          Cookies.get("token"),
          this.newOrder.type,
          searchParams
        )
        .then(function (response) {
          return response;
        })
        .catch(() => {
          this.showModalPopup("modal_popup_loading", false);
        })
        .then((response) => {
          if (response.status == 200) {
            this.customerBeneficiaries = response.data;
            this.orderStep++;
          } else if (response.status == 400 || response.status == 403) {
            this.orderError = {
              code: response.data,
              description: "Error fetching details for order " + this.orderId
            };
          } else {
            this.orderError = { description: response.description };
          }
          this.showModalPopup("modal_popup_loading", false);
        });
    },   
    showModalPopupVirtual(){
      if ((this.newOrder.type === "NEW" || this.newOrder.type === "REN") && this.userRole !== "FAC"  && this.newOrder.product.card !== 15){
        this.showModalPopup("modal_popup_virtual", true);
      } else {
        this.goToSummary();
      }
    } ,
    readVirtualBeneficiaries() {
      Cookies.set("virtualBeneficiaries", true);
      this.showModalPopup("modal_popup_virtual", false);
      this.goToSummary();
    },
    uploadListToServer() {
      this.actionOrderErrors = null;
      this.error = null;
      let bens = this.getBeneficiariesForSubmit();
      if (bens.length === 0) {
        return;
      }
      this.showModalPopup("modal_popup_info", false);
      var orderData = {
        product: 0,
        details: bens,
        customer_id: this.customer.company_id,
        type: this.newOrder.type === "PAPER" ? "EXNT" : this.newOrder.type
      };
      this.showModalPopup("modal_popup_loading", true);
      if (this.newOrder.type === "PAPER") {
        orderData.product = this.newOrder.product.paper;
        orderData.packing = this.newOrder.grouping;
        orderData.message1 = this.newOrder.text1
          ? this.newOrder.text1.normalize("NFD").replace(/\p{Diacritic}/gu, "")
          : this.newOrder.text1;
        orderData.message2 = this.newOrder.text2
          ? this.newOrder.text2.normalize("NFD").replace(/\p{Diacritic}/gu, "")
          : this.newOrder.text2;
        orderData.mappings = this.mappings;
        orderData.personalized = this.newOrder.personalized;
        orderData.logo = this.newOrder.logo;
        orderData.delivery_pickup = this.newOrder.delivery_pickup;

        orderData.saturday_delivery = this.newOrder.saturday_delivery;
        if (this.newOrder.product.paper === 2) {
          orderData.market = this.newOrder.destination;
        }
      } else {
        orderData.product = this.newOrder.product.card;
        orderData.charge_date = this.period[0];
        orderData.charge_month = this.newOrder.orderMonth;
        orderData.ignore_max_tickets = this.newOrder.ignoreMaxTickets;
        orderData.ignore_cnp_valid = this.newOrder.ignoreCnpValid;
        if (this.newOrder.product.card === 13) {
          orderData.market = this.newOrder.destination;
        }
        if (this.newOrder.product.card === 15) {
          orderData.valid_to_date = this.periodTo[0];
        }
        if (this.newOrder.type === "REN" && this.userRole === "FAC" ) {
          orderData.renew_month = this.newOrder.renewForCurrentMonth ? moment().month() + 1 : moment().month() + 2;
        }
      }

      httpServiceCustomer.customerService
        .uploadOrderFile(
          this.customer.company_id,
          orderData,
          this.token,
          this.userRole === "FAC" || this.userRole === "HLP" ? this.user.username : null
        )
        .then(function (response) {
          return response;
        })
        .catch((error) => {
          return error.response;
        })
        .then((response) => {
          if (response.status === 200) {
            let nextLink = response.headers["link"];
            nextLink = nextLink.split(";")[0];
            nextLink = nextLink.replace(">", "");
            nextLink = nextLink.replace("<", "");
            this.confirmationOrder = response.data;
            
            
            this.getConfirmationOrderDetails();
            
            this.newOrder.successLink = nextLink;
            this.error = null;
            this.actionOrderErrors = null;
            this.orderStep = 4;
            this.showModalPopup("modal_popup_loading", false);
          } else if (response.status === 406) {
            console.log("not acceptable");
            this.showModalPopup("modal_popup_loading", false);
            this.error = {code: "NOTACCEPTABLE"};
            this.showModalPopup("modal_popup_info", true);

          } else if (response.status === 400) {
            this.showModalPopup("modal_popup_loading", false);
            this.backOneStep();
          } else {
            this.getResponseErrors(response);
          }
        });
    },
    goToSummary() {
      if (this.newOrder.type === "NEWCR" && this.newOrder.product.card !== 11) {
        this.newOrder.orderMonth = new Date().getMonth() + 1;
      }
      if (this.newOrder.type === "NEWCR" && this.newOrder.orderMonth === 0) {
        this.error = { code: "NO_MONTH" };
        this.showModalPopup("modal_popup_info", true);
      } else if (
        this.newOrder.type === "NEWCR" &&
        this.checkVouchersBeneficiary()
      ) {
        this.error = { code: "NO_VOUCHERS_CUSTOMER" };
        this.showModalPopup("modal_popup_info", true);
      } else if (
        this.newOrder.type === "PAPER" &&
        this.order_type === false &&
        !(
          Object.values(this.mappings).includes(1) ||
          (Object.values(this.mappings).includes(2) &&
            Object.values(this.mappings).includes(3))
        )
      ) {
        this.error = { code: "NO_NAME" };
        this.showModalPopup("modal_popup_info", true);
      } else {
        if (!this.newOrder.content) {
          this.uploadListToServer();
        } else {
          this.uploadFileToServer();
        }
      }
    },
    checkVouchersBeneficiary() {
      for (let index in this.customerBeneficiaries) {
        if (this.customerBeneficiaries[index].vouchers === "") {
          this.indexBeneficiaryNoVouchers = parseInt(index) + 1;
          return true;
        }
      }
      return false;
    },
    confirmOrder() {
      // confirm customer order if there are no errors
      this.actionOrderErrors = null;
      this.error = null;
      this.showModalPopup("modal_popup_loading", true);

      if (
        this.newOrder.type === "NEWCR" &&
        this.confirmationOrder.details !== null &&
        this.confirmationOrder.details.length > 0
      ) {
        this.actionOrderErrors = [];
        for (let i in this.confirmationOrder.details) {
          let productCustomer = utils.getProductById(
            this.newOrder.product.card,
            this.customer.products
          );
          if (
            productCustomer.pinsMode === "IVR" &&
            (this.confirmationOrder.details[i].phone === null ||
              this.confirmationOrder.details[i].phone.length === 0 ||
              this.confirmationOrder.details[i].phone.length !== 10)
          ) {
            this.actionOrderErrors.push({
              error_code: "CARD.ERROR.MANDATORY",
              from_source_id: i + 1,
              description: "telefon "
            });
            // description: this.confirmationOrder[index].cnp });
          } else if (
            !this.confirmationOrder.details[i].address ||
            this.confirmationOrder.details[i].address === 0
          ) {
            // this.actionOrderErrors.push({
            //   error_code: "CARD.ERROR.MANDATORY",
            //   description: "adresa de livrare ",
            //   from_source_id: i + 1
            // });
            // description: this.confirmationOrder[index].cnp });
          }

          // validate phone
        }
        if (this.actionOrderErrors.length > 0) {
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        }
      }
      let detailsPag = this.confirmationOrder["detailsPag"];
      delete this.confirmationOrder["detailsPag"];
      httpServiceCustomer.customerService
        .confirmOrder(
          this.newOrder.successLink,
          this.confirmationOrder,
          this.token
        )
        .then((response) => {
          if (response.status !== 200) {
            this.actionOrderErrors = response.data.errors;
            this.error = { code: "500" };
            return;
          }
          this.showModalPopup("modal_popup_loading", false);
          this.orderStep = 5;
          let nextLink = response.headers["link"];
          nextLink = nextLink.split(";")[0];
          nextLink = nextLink.replace(">", "");
          nextLink = nextLink.replace("<", "");
          this.newOrder.successLink = nextLink;
          this.confirmationOrder = response.data;
        })
        .catch((error) => {
          this.confirmationOrder["detailsPag"] = detailsPag;
          this.getResponseErrors(error.response);
          // if (error.response) {
          //   if (error.response.data.code) {
          //     this.error = { code: error.response.data.code };
          //   } else {
          //     this.error = { code: "500" };
          //   }
          // } else {
          //   this.error = { code: "500" };
          // }
          // this.showModalPopup("modal_popup_info", true);
          // this.showModalPopup("modal_popup_loading", false);
        });
    },
    getOrderProform() {
      this.showModalPopup("modal_popup_loading", true);
      this.error = null;
      httpServiceCustomer.customerService
        .getOrderDocumentFile(this.newOrder.successLink, this.token, true)
        .then(function (response) {
          return response;
        })
        .then((response) => {
          if (response.status == 200) {
            var blob = new Blob(
              [utils.base64ToArrayBuffer(response.data.content)],
              {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel"
              }
            );
            saveAs(blob, response.data.invoice + ".pdf");
            this.showModalPopup("modal_popup_loading", false);
          } else if (response.status == 400 || response.status == 403) {
            this.error = { code: "PROFORM" };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.error = { code: "PROFORM" };
          }
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    verifyExcelColumns() {
      this.availableExcelColumns = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      for (let index in this.newOrder.excelColumns) {
        if (
          this.availableExcelColumns.includes(
            this.newOrder.excelColumns[index].code
          )
        ) {
          this.availableExcelColumns.splice(
            this.availableExcelColumns.indexOf(
              this.newOrder.excelColumns[index].code
            ),
            1
          );
        }
      }
      if (this.availableExcelColumns.includes(1)) {
        if (
          !this.availableExcelColumns.includes(2) ||
          !this.availableExcelColumns.includes(3)
        ) {
          this.availableExcelColumns.splice(
            this.availableExcelColumns.indexOf(1),
            1
          );
        }
      } else {
        this.availableExcelColumns.splice(
          this.availableExcelColumns.indexOf(2),
          1
        );
        this.availableExcelColumns.splice(
          this.availableExcelColumns.indexOf(3),
          1
        );
      }
      this.availableExcelColumns.push(0);

      this.availableExcelColumns.sort(function (a, b) {
        return a > b ? 1 : -1;
      });
    },
    onlyAlphabetCharacters(variable) {
      if (/^[a-zA-Z]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[0-9&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g, "");
      }
    },
    onlyNumericCharacters(variable) {
      if (/^[0-9]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(
          /[a-zA-Z&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g,
          ""
        );
      }
      return variable;
    },
    updateInputCharacters() {
      this.b_last_name = this.onlyAlphabetCharacters(this.b_last_name)
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "");
      this.b_first_name = this.onlyAlphabetCharacters(this.b_first_name)
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "");
      this.$forceUpdate();
    },
    updateEntryInputCharacters(entry) {
      entry.last_name = this.onlyAlphabetCharacters(entry.last_name)
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "");
      entry.first_name = this.onlyAlphabetCharacters(entry.first_name)
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "");
      this.$forceUpdate();
    },
    convertValueToDecimalVariable(variable) {
      if (/^-?\d*[.,]?\d*$/.test(variable)) {
        this.oldValue = variable;
      } else {
        variable = this.oldValue;
      }
      return variable;
    },
    formatTicketValue(variable) {
      return Number(variable)
        .toFixed(2)
        .toString()
        .replace(",", ".")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    mobilePhoneOnlyNumbers(number) {
      if (/^-?\d*$/.test(number)) {
        this.oldValue = number;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else {
        number = this.oldValue;
      }
      return number;
    },
    convertValueToDecimalVariableFromAPI(entry) {
      entry.nominal_value = this.convertValueToDecimalVariable(
        entry.nominal_value
      );
      this.$forceUpdate();
    },
    formatTicketValueFromAPI(entry) {
      entry.nominal_value = Number(entry.nominal_value)
        .toFixed(2)
        .toString()
        .replace(",", ".")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      this.$forceUpdate();
    },
    getAddressDisplayName(addressCode) {
      for (let index in this.deliveryAddresses) {
        if (this.deliveryAddresses[index].codcom === addressCode) {
          return (
            this.deliveryAddresses[index].codcom +
            " - " +
            this.deliveryAddresses[index].address.street_name +
            ", " +
            this.deliveryAddresses[index].address.locality
          );
        }
      }
      return addressCode;
    },
    customerHasPaperProduct(entry) {
      let hasProduct = false;
      if (
        entry.paper === "1" ||
        entry.paper === "2" ||
        entry.paper === "3" ||
        entry.paper === "7"
      ) {
        return false;
      }
      this.customer.products.forEach((prod) => {
        if (prod.paper === parseInt(entry.paper)) {
          hasProduct = true;
        }
      });
      return hasProduct;
    },
    customerHasCardProduct(entry) {
      let hasProduct = false;
      this.customer.products.forEach((prod) => {
        if (prod.card === parseInt(entry.card)) {
          hasProduct = prod.card === 12 && (this.newOrder.type == "NEW" || this.newOrder.type == "REN") ? true : prod.active;
        }
      });
      return hasProduct;
    },
    customerHasCardProductDejun() {
      let hasProduct = false;
      this.customer.products.forEach((prod) => {
        if (prod.card === 11) {
          hasProduct = true;
        }
      });
      return hasProduct;
    },
    // customerHasCardProductDejun() {
    //   let hasProduct = false;
    //   this.customer.products.forEach((prod) => {
    //     if (prod.card === 11) {
    //       hasProduct = true;
    //     }
    //   });
    //   return hasProduct;
    // },
    resetTableExcel() {
      this.newOrder.excelColumns = [
        { index: 1, code: 0 },
        { index: 2, code: 0 },
        { index: 3, code: 0 },
        { index: 4, code: 0 },
        { index: 5, code: 0 },
        { index: 6, code: 0 },
        { index: 7, code: 0 },
        { index: 8, code: 0 },
        { index: 9, code: 0 },
        { index: 10, code: 0 },
        { index: 11, code: 0 }
      ];
      this.verifyExcelColumns();
    },
    periodFilterChanged(value) {
      this.filters.period = value;
      this.getPreviousOrdersForProduct();
    },
    getBeneficiariesForSubmit: function () {
      let returnBeneficiaries = [];
      if (this.newOrder.type !== "NEWCR") {
        this.addBeneficiary();
      }
      if (this.newOrder.type === "PAPER") {
        for (let index in this.customerBeneficiaries) {
          returnBeneficiaries.push({
            cnp: this.customerBeneficiaries[index].cnp,
            cards: this.customerBeneficiaries[index].cards,
            first_name: this.customerBeneficiaries[index].first_name,
            last_name: this.customerBeneficiaries[index].last_name,
            vouchers: parseFloat(
              this.customerBeneficiaries[index].vouchers
                .toString()
                .replace(/ /g, "")
            ),
            nominal_value: parseFloat(
              this.customerBeneficiaries[index].nominal_value
                .toString()
                .replace(/ /g, "")
            ),
            address: this.customerBeneficiaries[index].address
          });
        }
      } else {
        for (let index in this.customerBeneficiaries) {
          if (
            (this.customerBeneficiaries[index].nominal_value !== "0" && 
            Number(this.customerBeneficiaries[index].vouchers) > 0) ||
            this.customerBeneficiaries[index].newBeneficiary
          ) {
            returnBeneficiaries.push({
              cnp: this.customerBeneficiaries[index].cnp,
              first_name: this.customerBeneficiaries[index].first_name,
              last_name: this.customerBeneficiaries[index].last_name,
              vouchers:
                this.newOrder.type === "NEWCR"
                  ? this.customerBeneficiaries[index].vouchers
                  : 0,
              cards: this.newOrder.type === "NEW" ? 1 : 0,
              nominal_value:
                this.newOrder.type === "NEWCR"
                  ? parseFloat(
                    this.customerBeneficiaries[index].nominal_value
                      .toString()
                      .replace(/ /g, "")
                  )
                  : 0,
              total_nominal_value:
                this.newOrder.type === "NEWCR"
                  ? parseFloat(
                    this.customerBeneficiaries[index].total_nominal_value
                      .toString()
                      .replace(/ /g, "")
                  )
                  : 0,
              address: this.customerBeneficiaries[index].address
                ? this.customerBeneficiaries[index].address
                : 1,
              card_virtual: this.customerBeneficiaries[index].card_virtual ? this.customerBeneficiaries[index].card_virtual : false,
              phone:
                this.newOrder.type === "NEW"
                  ? this.customerBeneficiaries[index].phone
                    ? this.customerBeneficiaries[index].phone.trim()
                    : ""
                  : "",
              email:
                this.newOrder.type === "NEW"
                  ? this.customerBeneficiaries[index].email
                    ? this.customerBeneficiaries[index].email.trim()
                    : ""
                  : ""
            });
          }
        }
      }
      return returnBeneficiaries;
    },
    selectDestination(event) {
      this.newOrder.destination = event.target.value;
    },
    isSelectedDestination(destination) {
      return destination === this.newOrder.destination;
    },
    addContentEditable() {
      if (this.userRole === "FAC") {
        document.getElementById("last_name").contentEditable = true;
        document
          .getElementById("last_name")
          .setAttribute("contenteditable", "true");
        document.getElementById("cnp").setAttribute("contenteditable", "true");
        document
          .getElementById("vouchers")
          .setAttribute("contenteditable", "true");
        document
          .getElementById("nominal_value")
          .setAttribute("contenteditable", "true");
      }
    },
    hasAnotherOrderForProduct() {
      this.todayOrder = 0;
      this.maxVNPopup = false;
      var ordersData =
        "?type=" +
        "customer" +
        "&order_type=" +
        this.newOrder.type +
        "&brand=" +
        this.newOrder.product.brand +
      "&start_date=" +
        moment().format("YYYYMMDD") +
        "&end_date=" +
        moment().format("YYYYMMDD");
      ordersData += "&status=L,N,P,S,V";
      ordersData += "&per_page=30&page=1&sort=" + this.sortDirection;
      if (this.sortDirection === "desc") {
        ordersData += "&desc=" + this.sortField;
      } else {
        ordersData += "&asc=" + this.sortField;
      }
      httpServiceCustomer.customerService
        .searchOrders(
          ordersData,
          this.customer.company_id,
          Cookies.get("token")
        )
        .then(function (response) {
          return response;
        })
        .catch(() => { })
        .then((response) => {
          if (response.status == 200) {
            this.todayOrder = response.headers["x-up-count"];
            // for (let i in response.data) {
            //   if (response.data[i]) {
            //     if (
            //       moment(response.data[i].date).format("YYYYMMDD") ===
            //       moment().format("YYYYMMDD")
            //     ) {
            //       this.todayOrder++;
            //     }
            //   }
            // }
          }
        });
    },
    convertVariablesOfProduct(product) {
      product.card = parseInt(product.card);
      product.order = parseInt(product.order);
      product.perso =
        product.perso === "true" || product.perso === true ? true : false;
      product.paper = parseInt(product.paper);
    },
    sendClickToGA(category, action, label) {
      if (this.$ga) {
        this.$ga.event({
          eventCategory: category,
          eventAction: action,
          eventLabel: label
        });
      }
    },
    sendMailForAdditionlContract() {
      this.sentEmailToCommercialError = null;
      this.successSentEmailToCommercial = false;

      httpServiceCustomer.customerService
        .sendEmailToCommecialPerson(
          this.customer.company_id,
          Cookies.get("token"),
          this.newOrder.product.paper
        )
        .then(function (response) {
          return response;
        })
        .then((response) => {
          if (response.status == 200) {
            this.successSentEmailToCommercial = true;
            this.showModalPopup("modal_popup_removeVouchers", false);
            // download content
          } else if (response.status == 400 || response.status == 403) {
            this.sentEmailToCommercialError = response.response;
            this.showModalPopup("modal_popup_removeVouchers", false);
          } else if (response.status == 404) {
            // show file not found
            this.sentEmailToCommercialError = response.response;
            this.showModalPopup("modal_popup_removeVouchers", false);
          }
        })
        .catch((error) => {
          this.sentEmailToCommercialError = {
            code: error.response.status
          };
          this.showModalPopup("modal_popup_removeVouchers", false);
        });
    },
    
  },

  filters: {

  },
  computed: {
    acceptedFileTypes() {
      if (this.customer.can_use_csv){
        return ".xls, .xlsx, .csv, .txt";
      } else {
        return ".xls, .xlsx";
      }
    
    },
    capitalizeName() {
      if (this.productCustomer && this.productCustomer.person) {
        return this.productCustomer.person.first_name.charAt(0) + this.productCustomer.person.first_name.slice(1).toLowerCase() + " " + this.productCustomer.person.last_name.charAt(0) + this.productCustomer.person.last_name.slice(1).toLowerCase();
      }
      return '';

    },
    paperProducts() {
      let paperProducts = [];
      for (let index in this.config.products) {
        if (
          this.config.products[index].paper > 0 &&
          this.config.products[index].paper !== "5" &&
          this.config.products[index].paper !== "1" &&
          this.config.products[index].paper !== "2" &&
          this.config.products[index].paper !== "3" &&
          this.config.products[index].paper !== "7"
        ) {
          paperProducts.push(this.config.products[index]);
        }
      }
      const filteredArr = paperProducts.filter(
        (el) =>
          el.card_profile !== "23" &&
          el.card_profile !== "28" &&
          el.card_profile !== "26"
      );
      return filteredArr;
    },
    cardProducts() {
      let cardProducts = [];
      for (let index in this.config.products) {
        if (parseInt(this.config.products[index].card) > 0) {
          if (
            // this.userRole === "FAC" ||
            this.config.products[index].forClient === "true"
          ) {
            cardProducts.push(this.config.products[index]);
          }
        }
      }
      return cardProducts;
    },
    orderProductVirtual() {
      return this.newOrder.product.virtual === "true" || this.newOrder.product.virtual === true;
    },
    orderProducts() {
      if (this.newOrder.type === "PAPER") {
        return this.paperProducts;
      } else if (
        this.newOrder.type === "NEW" ||
        this.newOrder.type === "NEWCR" ||
        this.newOrder.type === "REN"
      ) {
        return this.cardProducts;
      } else if (this.newOrder.type === "DUPLICATE") {
        return this.config.products;
      } else {
        return null;
      }
    },
    // filterDetails: function() {
    //   let result = this.confirmationOrder.details;

    //   result.sort((a, b) =>
    //     a[this.sortBy] < b[this.sortBy] ? -1 : a[this.sortBy] !== b[this.sortBy]
    //   );

    //   return this.reverse ? result.reverse() : result;
    // },
    mappings: function () {
      let mapping = {};
      for (let index in this.newOrder.excelColumns) {
        if (this.newOrder.excelColumns[index].code > 0) {
          let n = this.newOrder.excelColumns[index].index;
          mapping[n] = this.newOrder.excelColumns[index].code;
        }
      }
      return mapping;
    },
    getDestinations() {
      let destinationList = [];
      for (let index in destinations) {
        destinationList.push(destinations[index]);
      }
      if (this.newOrder.product.order === 3) {
        return destinationList.slice(0, destinationList.length - 1);
      } else if (
        this.newOrder.type === "NEWCR" &&
        this.newOrder.product.card === 13
      ) {
        return destinationList;
      } else {
        return destinationList.slice(destinationList.length - 1);
      }
    },
    productLogo: function () {
      if (this.newOrder.product) {
        for (let index in this.customer.products) {
          if (
            this.customer.products[index].paper === this.newOrder.product.paper
          ) {
            return this.customer.products[index].logo;
          }
        }
      }
      return null;
    },
    allVirtual() {
      if (this.order_type) {
        if (this.customerBeneficiaries && this.customerBeneficiaries.length > 0){
          for (let index in this.customerBeneficiaries) {
            if (!this.customerBeneficiaries[index].card_virtual || this.customerBeneficiaries[index].card_virtual === "false" ) {
              return false;
            }
          }
          return true;
        } else {
            return false;
        }
      } else {
        return false;
      }
    },
  },
  watch: {
    defaultVouchers: function () {
      this.setDefaultTicketsValueForNEWCR();
    },
    defaultTicketValue: function () {
      this.setDefaultNominalValueForNEWCR();
    },
    currentPage: function () {
      this.getConfirmationOrderDetails();
    }
  },
  mounted() {
    sessionStorage.removeItem("client_jsonFile");

    httpService.commonsService.getConfigFile(localStorage.language, "client");
    this.token = Cookies.get("token");
    this.verifyExcelColumns();
    if (this.customer.user_config && this.customer.user_config.config) {
      this.newOrder.excelColumns = [];
      for (let i = 1; i < 12; i++) {
        this.newOrder.excelColumns.push({
          index: i,
          code: this.customer.user_config.config[i]
            ? this.customer.user_config.config[i]
            : 0
        });
      }
      this.verifyExcelColumns();
      //order_type = true pentru web
      this.order_type = this.customer.employees_number < 30;
      let productUrl = utils.getUrlParam("product");
      if (productUrl) {
        let product = utils.getProductById(productUrl, this.config.products);
        this.selectOrderType("NEW");
        this.selectProduct(product);
        this.newOrder.fileBeneficiaries = true;
        this.newOrder.paper = 0;
        this.loadCustomerBeneficiaries();
        // this.showModalPopup("modal_popup_openLogo", false);
      }
    }
  }
};
</script>



<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.notification-action-small {
  display: block;
  width: 20%;
  padding-left: 55px;
  padding-right: 55px;
  padding-top: 12px;
  height: auto;
  padding-bottom: 12px;
  background: #f49400;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  border-bottom: 2px solid #ddd;
  text-transform: uppercase;
  text-align: center;
  margin: 30px auto;
}

.p-voucher {
  background: #fff url(../../../assets/images/order-paper-voucher.svg) no-repeat 20px center;
}

.p-voucher:hover {
  background: #f49400 url(../../../assets/images/order-paper-voucher.svg) no-repeat 20px center !important;
  color: #fff;
}

.border-solid-3 {
  border: 3px solid;
}

.top-up-cards {
  background: #fff url(../../../assets/images/topup-card.svg) no-repeat 20px center;
}

.plastic-cards {
  background: #fff url(../../../assets/images/order-plastic-cards.svg) no-repeat 20px center;
}

.duplicate-order {
  background: #fff url(../../../assets/images/duplicate-an-order.svg) no-repeat 20px center;
}

.renewal-card {
  background: #fff url(../../../assets/images/renewal-card.svg) no-repeat 5px center;
}

.top-up-cards:hover {
  background: #f49400 url(../../../assets/images/topup-card.svg) no-repeat 20px center;
  color: #fff;
}

.plastic-cards:hover {
  background: #f49400 url(../../../assets/images/order-plastic-cards.svg) no-repeat 20px center;
  color: #fff;
}

.duplicate-order:hover {
  background: #f49400 url(../../../assets/images/duplicate-an-order.svg) no-repeat 20px center;
  color: #fff;
}

.renewal-card:hover {
  background: #f49400 url(../../../assets/images/renewal-card.svg) no-repeat 5px center;
  color: #fff;
}

.checkbox-custom,
.radio-custom {
  position: relative;
  top: 2px;
  margin-right: 5px;
}

input[type="text"].input-msg {
  width: 100%;
  height: auto;
  margin: 0px;
  display: block;
  font-size: 13px;
  padding: 10px;
  box-sizing: border-box;
}

.msg-label {
  padding-top: 10px;
  display: block;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-top-5 {
  margin-top: 5px;
}

.table-label {
  display: block;
}

select.select-column {
  width: 79%;
}

.personalised-vouchers {
  display: block;
  width: 200px;
  height: 41px;
  background: url(../../../assets/images/p-vouchers.svg) no-repeat top left;
  background-color: #fff;
  background-position: 10px 9px;
  float: left;
  padding-left: 80px;
  padding-top: 15px;
  text-decoration: none;
  margin: 10px 20px 10px 0;
  color: #585858;
  cursor: pointer;
}

.personalised-vouchers:hover {
  opacity: 0.5;
}

.personalised-vouchers.active {
  background-color: #f49400;
  color: #fff;
}

.personalised-vouchers.active:hover .nonpersonalised-vouchers.active:hover {
  opacity: 1;
}

.nonpersonalised-vouchers {
  display: block;
  width: 200px;
  height: 41px;
  background: url(../../../assets/images/non-p-vouchers.svg) no-repeat top left;
  background-color: #fff;
  background-position: 10px 9px;
  float: left;
  padding-left: 80px;
  padding-top: 15px;
  text-decoration: none;
  margin: 10px 20px 10px 0;
  color: #585858;
  cursor: pointer;
}

.nonpersonalised-vouchers:hover {
  opacity: 0.5;
}

.nonpersonalised-vouchers.active {
  background-color: #f49400;
  color: #fff;
}

.navigationSlider.order-type a {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.order-type a:not([href]):not([tabindex]) {
  color: #4a4a4a;
}

.order-type .active:not([href]):not([tabindex]) {
  color: #f79000;
}

.scroll-add-new-beneficiary {
  display: block;
  width: 100%;
  overflow-y: scroll;
  max-height: 500px;
  border-top: 21px solid #f1f1f1;
}

.scroll-add-new-beneficiary .invoices-filter {
  display: inline-block;
}

.currencyinput {
  display: inline-block;
  border-bottom: 1px solid #d7d7d7;
  padding: 5px;
  background: transparent;
  color: #4a4a4a;
}

.currencyinput input[type="text"].input-add-beneficiary,
.currencyinput input[type="number"].input-add-beneficiary {
  padding: 0;
  border: 0;
  background-color: transparent;
}

.currencyinput input[type="text"].input-add-beneficiary.amount,
.currencyinput input[type="number"].input-add-beneficiary.amount {
  width: calc(100% - 20px);
}

.currencyinput select {
  height: auto;
  width: auto;
  background: transparent;
}

tr.trhover.background-will-expire .currencyinput {
  background: #fff;
}

tr.trhover:not(.background-will-expire):hover .currencyinput,
tr.trhover:not(.background-will-expire):hover .currencyinput select {
  color: #fff;
}

tr.trhover:not(.background-will-expire):hover .currencyinput select {
  background: #4e5b59;
}

.numberOfTicket {
  width: 65px;
}

input[type="text"].numberOfTicket {
  width: 65px;
}

.padding-top-0 {
  padding-top: 0;
}

.input-cnp {
  min-width: 106px;
}

.error-scroll {
  max-height: 500px;
  overflow-y: auto;
}

.warning-text {
  color: #f79000;
}

.warning-max-value {
  background: #d48b1a !important;
  color: #fff !important;
}

.text-white {
  color: #fff;
}

.box-shadow-error {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 1);
}

.image-button {
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  background: #fff;
  padding: 2px 7px;
  border-radius: 15px;
  font-weight: bold;
  text-transform: uppercase;
  width: max-content;
}

.maxVnPopupWidth {
  width: 100%;
  max-width: 600px;
}

@media (max-width: 700px) {
  .maxVnPopupWidth {
    width: auto;
    max-width: 80%;
  }
}
</style>
